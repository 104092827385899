import React from "react";
import { doc, Timestamp, updateDoc } from "firebase/firestore";
import { toastr } from "react-redux-toastr";
import { useNavigate } from "react-router-dom";

import { newFirestore } from "utils/firebase";

const UpdateStatus = ({ value, row, userData }) => {
  const navigate = useNavigate();
  const originalData = row?.original;

  const onChange = async (e) => {
    try {
      toastr.info("Updating status", "Please wait...");
      await updateDoc(doc(newFirestore, "issues", originalData.id), {
        status: e.target.value,
        dateUpdated: Timestamp.now(),
        updatedBy: userData?.email,
      });
      toastr.success("Updated", "Reloading...");
      navigate(0);
    } catch (error) {
      console.log(error);
      toastr.error(error?.message);
    }
  };
  const options = ["RECEIVED", "IN_PROGRESS", "SCHEDULED", "AWAITING_MATERIALS", "PENDING_APPROVAL", "UNDER_REVIEW", "ON_HOLD", "RESOLVED", "CLOSED"];

  return (
    <select className="w-40 appearance-none rounded border-gray-300 py-1 outline-none" value={value} onChange={onChange}>
      <option disabled value="NEW">
        NEW
      </option>
      {options.map((option, i) => {
        return (
          <option key={i} value={option}>
            {option.replace(/_/g, " ")}
          </option>
        );
      })}
    </select>
  );
};

export default UpdateStatus;
