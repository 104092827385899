import { addMinutes, format, isAfter } from "date-fns";
import { download, generateCsv, mkConfig } from "export-to-csv";
import english from "languages/en";
import spanish from "languages/es";
import { isEmpty, omit } from "lodash";
import { createIntl, createIntlCache } from "react-intl";
import { toastr } from "react-redux-toastr";

import { logout } from "state/actions/auth";
import en from "assets/en.png";
import es from "assets/es.png";

import CSVButton from "components/Buttons/CSVButton";

import { newAuth } from "./firebase";

export const FIREBASE_RESPONSE = {
  EMAIL_IN_USE: "auth/email-already-exists",
  EMAIL_INVALID: "auth/invalid-email",
  EMAIL_NOT_FOUND: "auth/user-not-found",
  PASSWORD_INVALID: "auth/wrong-password",
  USER_DISABLED: "auth/user-disabled",
  TOO_MANY_REQUESTS: "auth/too-many-requests",
  EXPIRED_ACTION_CODE: "auth/expired-action-code",
  INVALID_ACTION_CODE: "auth/invalid-action-code",
  QUOTA_EXCEEDED_STORAGE: "storage/quota-exceeded",
  UNAUTHENTICATED_STORAGE: "storage/unauthenticated",
  UNAUTHORIZED_STORAGE: "storage/unauthorized",
};

export const messages = {
  en: english,
  es: spanish,
};

const getIntlContext = (locale) => {
  const cache = createIntlCache();
  return createIntl(
    {
      locale,
      messages: messages[locale],
    },
    cache,
  );
};

export const firebaseError = (error, locale = "en") => {
  const intl = getIntlContext(locale);
  return intl.formatMessage({
    id: error,
    defaultMessage: messages[locale]["utils.default"],
  });
};

export const availableLocales = Object.keys(messages);

export const browserLocale = navigator.language.split(/[-_]/)[0];

export const flags = {
  en,
  es,
};

export const uiConfig = (onSignInSuccessHandler, onSignInFailHandler) => {
  return {
    callbacks: {
      signInSuccessWithAuthResult: onSignInSuccessHandler,
      signInFailure: onSignInFailHandler,
    },
    signInFlow: "popup",
    signInSuccessUrl: "/home",
    signInOptions: [
      {
        provider: newAuth.GoogleAuthProvider.PROVIDER_ID,
        fullLabel: "Continue with Google",
        scopes: ["https://www.googleapis.com/auth/user.addresses.read", "https://www.googleapis.com/auth/userinfo.email"],
      },
      {
        provider: newAuth.FacebookAuthProvider.PROVIDER_ID,
        fullLabel: "Continue with Facebook",
        scopes: ["email"],
      },
      { provider: "microsoft.com", fullLabel: "Continue with Microsoft" },
    ],
  };
};

export const genRandomString = (length = 10) => {
  let text = "";
  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charLen = parseInt(length);

  for (var i = 0; i < charLen; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text.toUpperCase();
};

export const sendExpoNotificaiton = async (data = [], title, body) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      to: data,
      title,
      body,
      priority: "high",
      sound: "default",
      badge: 0,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch("https://thingproxy.freeboard.io/fetch/https://exp.host/--/api/v2/push/send", requestOptions);
    return response.text();
  } catch (error) {
    console.log(error);
  }
};

export const getTableDataForExport = (data, columns, headerName = "Header") => {
  return data?.map((record) =>
    columns.reduce(
      (recordToDownload, column) => ({
        ...recordToDownload,
        [column[headerName]]: record[column.accessor] || "N/A",
      }),
      {},
    ),
  );
};

export const CSVOptions = {
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  showTitle: true,
  // title: estateData?.name,
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
  // filename: estateData?.name,
  // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
};

export function checkstuff(event) {
  if (event.target.value.substr(-1) === " " && event.code === "Space") {
    event.target.value = event.target.value.substr(0, event.target.value.length - 1);
  }

  var evt = event ? event : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;

  if (charCode > 32 && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
    console.log(charCode);
    return false;
  }

  return true;
}

export const onFileChange = async (e, action = () => {}) => {
  if (e.target.files && e.target.files.length > 0) {
    const file = e.target.files[0];
    let imageDataUrl = await readFile(file);
    action(imageDataUrl);
  }
};

export function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

export const onImageFileChange = async (e) => {
  if (e.target.files && e.target.files.length > 0) {
    const file = e.target.files[0];
    let imageDataUrl = await readFile(file);
    return imageDataUrl;
  }
};

export const DEFAULT_ITEM_IMAGE_PATH = `https://storage.googleapis.com/${process.env.REACT_APP_FIRE_BASE_STORAGE_BUCKET}/default_item.jpeg`;

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const autoLogout = async ({ dispatch, event }) => {
  const timeoutKey = localStorage.getItem("@our-gatekeeper/admin.timeout");

  if (!timeoutKey) {
    const oneMinuteAfter = addMinutes(new Date(), 60);
    const toISO = oneMinuteAfter.toISOString();
    localStorage.setItem("@our-gatekeeper/admin.timeout", toISO);
  } else {
    const toDate = new Date(timeoutKey);
    const trueIsAfter = isAfter(toDate, new Date());
    if (!trueIsAfter) {
      toastr.error("Your session has expired. Please login again!!");
      dispatch(logout());
      const oneMinuteAfter = addMinutes(new Date(), 60);
      const toISO = oneMinuteAfter.toISOString();
      localStorage.setItem("@our-gatekeeper/admin.timeout", toISO);
    } else {
      const oneMinuteAfter = addMinutes(new Date(), 60);
      const toISO = oneMinuteAfter.toISOString();
      localStorage.setItem("@our-gatekeeper/admin.timeout", toISO);
    }
  }
};

export const withOutId = (userData, params = ["id"]) => isEmpty(omit(userData, params));

export const createArray = (length) => {
  return Array.from({ length }, (_, index) => index);
};

export const complaintStatusColors = {
  // NEW: "#a8aab5",
  RECEIVED: "#FF5733",
  IN_PROGRESS: "#FFC300",
  SCHEDULED: "#4b96b1",
  AWAITING_MATERIALS: "#C70039",
  PENDING_APPROVAL: "#900C3F",
  UNDER_REVIEW: "#581845",
  ON_HOLD: "#FF5733",
  RESOLVED: "#28B463",
  CLOSED: "#468f48",
};

export const tableOptions = {
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  showTitle: true,
  title: "Complaints",
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
  filename: "Complaints",
};

export const tableActionButtons =
  ({ ref, columns, omittedColumns, transformData = () => {}, ExtraComponents = () => {}, ...props }) =>
  () => {
    const processCSV = () => {
      try {
        const data = ref.current.setSelectedRows();

        if (!data?.length) {
          return toastr.error("Empty data", "Select rows to export");
        }

        const returnResult = getTableDataForExport(
          data.map((item) => item?.original),
          columns,
        );

        const formatData = (data) =>
          data.map((item) => {
            return {
              ...omit(item, [omittedColumns]),
              ...transformData(item),
            };
          });

        const csvConfig = mkConfig({ ...tableOptions });
        const csv = generateCsv(csvConfig)(formatData(returnResult));
        download(csvConfig)(csv);
      } catch (error) {
        console.log(error);
      }
    };

    return (
      <div className="my-4 flex w-full flex-wrap items-center justify-center gap-4">
        <CSVButton action={processCSV} />
        <ExtraComponents {...props} />
      </div>
    );
  };

export const replaceElementById = (id, newElement, hrefTransform = (href) => href) => {
  const element = document.getElementById(id);
  if (element && element.parentNode) {
    if (element.parentNode.href) {
      element.parentNode.href = hrefTransform(element.parentNode.href);
    }
    element.parentNode.replaceChild(newElement, element);
  }
};

export const generateAccessCode = (length) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < length; i++) {
    if (i % 2 === 0) {
      // even index, add a letter
      result += characters.charAt(Math.floor(Math.random() * 52)); // 52 letters in the characters string
    } else {
      // odd index, add a number
      result += characters.charAt(Math.floor(Math.random() * 10) + 52); // 10 numbers in the characters string starting at index 52
    }
  }
  return result?.toUpperCase();
};

// write me a function that takes two dates and returns the an array of month - year up until the end date
export const getMonths = (startDate, endDate) => {
  const months = [];
  let currentDate = startDate;

  while (currentDate <= endDate) {
    months.push(`${format(currentDate, "LLLL")} - ${format(currentDate, "yyyy")}`);
    currentDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
  }

  return months;
};

export function calculateGroups(startDateStr, endDateStr, frequency) {
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  const totalMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth() + 1);

  // console.log({ startDate, endDate, frequency ,totalMonths});

  switch (frequency) {
    case "annually":
      return { totalMonths, total: Math.floor(totalMonths / 12), chunk: 12 };
    case "bi-annually":
      return { totalMonths, total: Math.floor(totalMonths / 6), chunk: 6 };
    case "quarterly":
      return { totalMonths, total: Math.floor(totalMonths / 3), chunk: 3 };
    case "monthly":
      return { totalMonths, total: totalMonths, chunk: 1 };
    default: {
      console.warn(`Invalid frequency ===> ${frequency}. Choose from 'annually', 'bi-annually', 'quarterly', or 'monthly'.`);
      return { totalMonths, total: 0, chunk: 1 };
    }
  }
}

export const fileTypes = {
  "image/jpeg": "jpg",
  "image/png": "png",
  "application/pdf": "pdf",
  "application/msword": "doc",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
};
