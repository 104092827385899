import { format } from "date-fns";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";

import { replaceElementById, tableActionButtons } from "utils";
import { newFirestore } from "utils/firebase";

import AssignFO from "components/Misc/AssignFO";
import TableTopComponent from "components/Misc/TableTopComponent";
import UpdateStatus from "components/Misc/UpdateStatus";
import { SelectColumnFilter } from "components/Table";

export const getAllComplaints = async ({ estateData }) => {
  const { estateID, type } = estateData;
  const docs = [];
  const querySnapshot = await getDocs(
    query(collection(newFirestore, "issues"), where("estateID", "==", estateID), where("topic", "==", `${type}Issues`), orderBy("dateUpdated", "desc")),
  );

  querySnapshot.forEach((doc) => {
    const data = doc?.data();
    docs.push(data);
  });

  return docs;
};

export const columns = ({ userClaims, dispatch, navigate, userData, changedProps, setChangedProps }) => {
  return [
    {
      Header: "Actions",
      id: "actions",
      accessor: "actions",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <>
            <AssignFO {...{ changedProps, setChangedProps, row }} />
            <div className="buttons is-left">
              <button
                className="bg-green-500 px-2 py-1 text-white"
                onClick={() => {
                  setChangedProps((data) => ({ ...data, issueIDAssignFO: row.original.issueID }));
                }}
              >
                Assign
              </button>
            </div>
          </>
        );
      },
    },
    {
      Header: "Reference",
      accessor: "referenceCode",
      Filter: SelectColumnFilter,
    },
    {
      Header: "Category",
      accessor: "category",
      Filter: SelectColumnFilter,
    },
    {
      Header: "Sub Category",
      accessor: "subCategory",
      Filter: SelectColumnFilter,
    },
    {
      Header: `Complaint`,
      accessor: "complaint",
      Filter: SelectColumnFilter,
      width: 250,
    },
    {
      Header: `Asset`,
      accessor: "image",
      Cell: ({ row, value }) => {
        return (
          <>
            {value ? (
              <a href={value} target="_blank">
                <img
                  id={row.original.issueID}
                  src={value}
                  className="hover:opacity-50"
                  onError={() => {
                    const video = document.createElement("video");
                    video.src = value;
                    video.autoplay = false;
                    video.setAttribute("controls", "controls");
                    video.setAttribute("dowload", "controls");
                    replaceElementById(row.original.issueID, video);
                  }}
                />
              </a>
            ) : (
              <p>N/A</p>
            )}
          </>
        );
      },
    },
    {
      Header: `Notes`,
      accessor: "message",
    },
    {
      Header: `Status`,
      accessor: "status",
      Cell: (props) => <UpdateStatus {...{ ...props, userData }} />,
      Filter: SelectColumnFilter,
    },
    {
      Header: `Raised For`,
      accessor: "uid",
      // Cell: ({ row }) => {
      //   return <p>{(row.original.name || "N/A") + "|" + (row.original.phoneNumber || "N/A")}</p>;
      // },
      // width: 250,
      Cell: ({ value }) => {
        return <p>{value || "N/A"}</p>;
      },
    },
    {
      Header: `Unit`,
      accessor: "houseNumber",
      Cell: ({ value }) => {
        return <p>{value || "N/A"}</p>;
      },
    },
    {
      Header: `Facility`,
      accessor: "estateName",
    },
    {
      Header: `Assigned To`,
      accessor: "assignedFO",
      Cell: ({ value }) => {
        return <p>{value || "N/A"}</p>;
      },
      Filter: SelectColumnFilter,
    },
    {
      Header: `Assigned To (Phone Number)`,
      accessor: "assignedFOPhoneNumber",
      Cell: ({ value }) => {
        return <p>{value || "N/A"}</p>;
      },
    },
    {
      Header: `Assigned To (Email)`,
      accessor: "assignedFOEmail",
      Cell: ({ value }) => {
        return <p>{value || "N/A"}</p>;
      },
    },
    {
      Header: `Date Sent`,
      accessor: "dateSent",
      Cell: ({ value }) => {
        return <p>{value ? format(value?.toDate(), "PPPp") : "N/A"}</p>;
      },
      width: 250,
    },
    {
      Header: `Date Updated`,
      accessor: "dateUpdated",
      Cell: ({ value }) => {
        return <p>{value ? format(value?.toDate(), "PPPp") : "N/A"}</p>;
      },
      width: 250,
    },
    {
      Header: `Raised By`,
      accessor: "raisedBy",
      Cell: ({ value }) => {
        return <p>{value || "N/A"}</p>;
      },
    },
    {
      Header: `Updated By`,
      accessor: "updatedBy",
      Cell: ({ value }) => {
        return <p>{value || "N/A"}</p>;
      },
    },
    {
      Header: `Complaint ID`,
      accessor: "issueID",
    },
  ];
};

const actionButtons = (props) =>
  tableActionButtons({
    ...props,
    omittedColumns: ["Actions"],
    transformData: (item) => ({
      "Date Sent": typeof item["Date Sent"] === "string" ? item["Date Sent"] : item["Date Sent"]?.toDate()?.toUTCString(),
      "Date Updated": typeof item["Date Updated"] === "string" ? item["Date Updated"] : item["Date Updated"]?.toDate()?.toUTCString(),
    }),
  });

export default { columns, getAllComplaints, TableTopComponent, actionButtons };
