import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { httpsCallable } from "firebase/functions";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { toastr } from "react-redux-toastr";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { newFunction } from "utils/firebase";

const AssignFO = ({ changedProps, setChangedProps, row }) => {
  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    emailAddress: yup.string().email(),
    phoneNumber: yup.string().min(10, "Phone number length must be 10 characters").max(10, "Phone number length must be 10 characters").required(),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const originalData = row?.original;

  const showPreview = useMemo(
    () => changedProps?.issueIDAssignFO && originalData.issueID && changedProps?.issueIDAssignFO === originalData.issueID,
    [changedProps, originalData],
  );

  const [processing, setProcessing] = useState(false);

  const onSubmit = async (values) => {
    try {
      setProcessing(true);
      toastr.info("Assigning", "Please wait...");
      const assignFO = httpsCallable(newFunction, "complaints-assignFO");
      const { data } = await assignFO({ values, originalData });

      if (data?.status) {
        toastr.success("Assigned", "Reloading...");
        setChangedProps((data) => ({ ...data, issueIDAssignFO: null }));
        navigate(0);
      }
    } catch (error) {
      console.log(error);
      toastr.error(error?.message);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div id="image-preview" className={`modal ${showPreview ? "is-active" : ""}`}>
      <div className="modal-background"></div>
      <div className="modal-content rounded">
        <div className="w-full bg-white p-3">
          <h1 className="mb-5 text-center text-xl font-medium">Assign To (send SMS and email)</h1>
          <div className="flex w-full justify-center">
            <div className="w-7/12">
              <div className="flex w-full flex-col gap-2">
                <input type="text" placeholder="Enter name" {...register("name")} />
                <p className="text-red-500">{errors?.name?.message}</p>
                <input type="tel" placeholder="Enter phone number" {...register("phoneNumber")} />
                <p className="text-red-500">{errors?.phoneNumber?.message}</p>
                <input type="email" placeholder="Enter email address" {...register("emailAddress")} />
                <p className="text-red-500">{errors?.emailAddress?.message}</p>
                <div className="">
                  <button disabled={processing} className="button is-primary" onClick={handleSubmit(onSubmit)}>
                    Assign
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={() => {
          setChangedProps((data) => ({ ...data, issueIDAssignFO: null }));
        }}
      />
    </div>
  );
};

export default AssignFO;
