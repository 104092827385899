import { collection, getDocs, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { pick } from "lodash";
import { toastr } from "react-redux-toastr";
import { createAction } from "redux-act";

import { firebaseError } from "utils";

import { deleteDocument, updateDocument } from "../api";
import { newFirestore, newFunction } from "../../utils/firebase";

export const USERS_FETCH_DATA_INIT = createAction("USERS_FETCH_DATA_INIT");
export const USERS_SUCCESS = createAction("USERS_SUCCESS");
export const USERS_FAIL = createAction("USERS_FAIL");

export const USER_GET_ESTATE_DATA = createAction("USER_GET_ESTATE_DATA");
export const USERS_CLEAN_UP = createAction("USERS_CLEAN_UP");
export const USERS_CLEAR_DATA_LOGOUT = createAction("USERS_CLEAR_DATA_LOGOUT");

// COMMERCIAL/NINJA
export const GET_ALL_USER_ESTATES = createAction("GET_ALL_USER_ESTATES");

export const fetchUserEstates = ({ estateIDs, proceed = () => {}, showNotif = true }) => {
  return async (dispatch, getState) => {
    try {
      if (!estateIDs || !estateIDs.length) return proceed();
      // dispatch(USERS_FETCH_DATA_INIT());
      showNotif && toastr.info("Fetching properties", "Please wait...", { timeOut: 500 });
      const ref = await getDocs(
        query(collection(newFirestore, `estates`), where("uid", "in", estateIDs), where("type", "==", "facility"), orderBy("dateAdded", "desc")),
      );
      const allUserEstates = [];
      ref.forEach((doc) => {
        allUserEstates.push(doc.data());
      });

      dispatch(
        GET_ALL_USER_ESTATES({
          allUserEstates,
        }),
      );

      dispatch(USERS_SUCCESS());
      // onSnapshot(query(collection(newFirestore, `estates`), where("uid", "in", estateIDs), orderBy("dateAdded", "desc")), async (querySnapshot) => {
      //   const allUserEstates = [];
      //   querySnapshot.forEach((doc) => {
      //     allUserEstates.push(doc.data());
      //   });

      //   dispatch(
      //     GET_ALL_USER_ESTATES({
      //       allUserEstates,
      //     }),
      //   );
      // });
      proceed();
    } catch (error) {
      console.log(error);
      toastr.error("", error);
      return dispatch(USERS_FAIL({ error }));
    }
  };
};

export const fetchResidentialProperties = ({ estateIDs, proceed = () => {}, showNotif = true }) => {
  return async (dispatch, getState) => {
    try {
      if (!estateIDs || !estateIDs.length) return proceed();
      // dispatch(USERS_FETCH_DATA_INIT());
      showNotif && toastr.info("Fetching properties", "Please wait...", { timeOut: 500 });
      const ref = await getDocs(
        query(collection(newFirestore, `estates`), where("uid", "in", estateIDs), where("type", "==", "residential"), orderBy("dateAdded", "desc")),
      );
      const allResidentialProperties = [];
      ref.forEach((doc) => {
        allResidentialProperties.push(doc.data());
      });

      dispatch(
        GET_ALL_USER_ESTATES({
          allResidentialProperties,
        }),
      );

      dispatch(USERS_SUCCESS());
      // onSnapshot(query(collection(newFirestore, `estates`), where("uid", "in", estateIDs), orderBy("dateAdded", "desc")), async (querySnapshot) => {
      //   const allResidentialProperties = [];
      //   querySnapshot.forEach((doc) => {
      //     allResidentialProperties.push(doc.data());
      //   });

      //   dispatch(
      //     GET_ALL_USER_ESTATES({
      //       allResidentialProperties,
      //     }),
      //   );
      // });
      proceed();
    } catch (error) {
      console.log(error);
      toastr.error("", error);
      return dispatch(USERS_FAIL({ error }));
    }
  };
};

export const fetchCommercialProperties = ({ commercialPropertyIDs, proceed = () => {}, showNotif = true }) => {
  return async (dispatch, getState) => {
    try {
      if (!commercialPropertyIDs || !commercialPropertyIDs.length) return proceed();
      // dispatch(USERS_FETCH_DATA_INIT());
      showNotif && toastr.info("Fetching properties", "Please wait...", { timeOut: 500 });
      const ref = await getDocs(
        query(collection(newFirestore, `estates`), where("uid", "in", commercialPropertyIDs), where("type", "==", "commercial"), orderBy("dateAdded", "desc")),
      );
      const allCommercialProperties = [];
      ref.forEach((doc) => {
        allCommercialProperties.push(doc.data());
      });

      dispatch(
        GET_ALL_USER_ESTATES({
          allCommercialProperties,
        }),
      );

      dispatch(USERS_SUCCESS());

      // onSnapshot(query(collection(newFirestore, `estates`), where("uid", "in", commercialPropertyIDs), orderBy("dateAdded", "desc")), async (querySnapshot) => {
      //   const allCommercialProperties = [];
      //   querySnapshot.forEach((doc) => {
      //     allCommercialProperties.push(doc.data());
      //   });

      //   dispatch(
      //     GET_ALL_USER_ESTATES({
      //       allCommercialProperties,
      //     }),
      //   );
      // });
      proceed();
    } catch (error) {
      console.log(error);
      toastr.error("", error);
      return dispatch(USERS_FAIL({ error }));
    }
  };
};

export const deleteUser = (id) => {
  return async (dispatch, getState) => {
    dispatch(USERS_FETCH_DATA_INIT());
    const { locale } = getState().preferences;
    const { logoUrl } = getState()
      .users.data.filter((user) => user.id === id)
      .pop();

    const deleteLogoTask = logoUrl ? deleteLogo(logoUrl) : null;

    const deleteUserTask = deleteDocument("users", id);

    try {
      await Promise.all([deleteLogoTask, deleteUserTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error("", errorMessage);
      return dispatch(
        USERS_FAIL({
          error: errorMessage,
        }),
      );
    }

    toastr.success("", "The user was deleted.");
    return dispatch(USERS_SUCCESS({ id }));
  };
};

export const clearUsersDataLogout = () => {
  return (dispatch) => {
    dispatch(USERS_CLEAR_DATA_LOGOUT());
  };
};

export const createResident = (allData) => {
  return async (dispatch, getState) => {
    dispatch(USERS_FETCH_DATA_INIT());
    const { locale } = getState().preferences;

    let response;
    try {
      const addNewUser = httpsCallable(newFunction, "addNewUser");
      response = await addNewUser(allData);
    } catch (error) {
      const errorMessage = firebaseError(error.message, locale);
      toastr.error("", errorMessage);
      return dispatch(
        USERS_FAIL({
          error: errorMessage,
        }),
      );
    }

    if (response.data?.status) {
      toastr.success("", response.data?.response);

      allData?.reset({
        name: "",
        emailAddress: "",
        houseNumber: "",
        userGroup: "",
        userType: "",
        phoneNumber: "",
        gender: "",
        adults: "",
        kids: "",
        checkIn: "",
        checkOut: "",
        streetName: "",
        floorNumber: "",
        isPrimaryUser: false,
      });

      return dispatch(USERS_SUCCESS());
    } else {
      toastr.error("", response.data?.response);
      return dispatch(
        USERS_FAIL({
          error: response.data?.response,
        }),
      );
    }
  };
};

export const addAdmin = (allData) => {
  return async (dispatch) => {
    dispatch(USERS_FETCH_DATA_INIT());

    let response;
    try {
      const addNewAdmin = httpsCallable(newFunction, "addNewAdmin");
      response = await addNewAdmin(allData);
    } catch (error) {
      const errorMessage = firebaseError(error.message);
      toastr.error("", errorMessage);
      return dispatch(
        USERS_FAIL({
          error: errorMessage,
        }),
      );
    }

    if (response.data?.status) {
      toastr.success("", response.data?.response, { timeOut: 0 });
      return dispatch(USERS_SUCCESS());
    } else {
      toastr.error("", response.data?.response);
      return dispatch(USERS_FAIL(response.data?.response));
    }
  };
};

export const addEmergencyContact = (allData) => {
  return async (dispatch) => {
    dispatch(USERS_FETCH_DATA_INIT());

    let response;
    try {
      const addNewEmergencyContact = httpsCallable(newFunction, "addNewEmergencyContact");

      response = await addNewEmergencyContact(allData);
    } catch (error) {
      const errorMessage = firebaseError(error.message);
      toastr.error("", errorMessage);
      return dispatch(
        USERS_FAIL({
          error: errorMessage,
        }),
      );
    }

    if (response.data?.status) {
      toastr.success("", response.data?.response);
      return dispatch(USERS_SUCCESS());
    } else {
      toastr.error("", response.data?.response);
      return dispatch(USERS_FAIL(response.data?.response));
    }
  };
};

export const modifyUser = (data, estateData) => {
  return async (dispatch, getState) => {
    try {
      dispatch(USERS_FETCH_DATA_INIT());
      const userData = {
        ...estateData,
        uid: data?.id,
        ...pick(data, [
          "name",
          "userGroup",
          "userType",
          "id",
          "emailAddress",
          "gender",
          "streetName",
          "isPrimaryUser",
          "uid",
          "userCreateDate",
          "phoneNumber",
          "houseNumber",
        ]),
      };
      // console.log(userData);
      // return;
      const updateUserDetails = httpsCallable(newFunction, "updateUserDetails");
      await updateUserDetails({ userData });

      toastr.success("", "User updated successfully");
      return dispatch(USERS_SUCCESS({ user: userData, id: data?.id }));
    } catch (error) {
      const errorMessage = error?.message;
      toastr.error("", errorMessage);
      return dispatch(
        USERS_FAIL({
          error: errorMessage,
        }),
      );
    }
  };
};

export const usersCleanUp = () => (dispatch) => dispatch(USERS_CLEAN_UP());
