import React from "react";

const CSVButton = ({ title = "Export to CSV", action, classes }) => {
  return (
    <button className={`rounded bg-brandIceberg px-2 py-1 text-white shadow ${classes}`} onClick={action}>
      {title}
    </button>
  );
};

export default CSVButton;
