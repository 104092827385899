import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

Sentry.init({
  enabled: process.env.NODE_ENV === "production",
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      colorScheme: "system",
      isNameRequired: true,
    }),
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    // Sentry.captureConsoleIntegration({ levels: ["warn", "error"] }),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllImages: false,
      maskAllVectors: false,
      mask: ["is-password-field"],
      maskAllInputs: false,
    }),

    // The following is all you need to enable canvas recording with Replay
    Sentry.replayCanvasIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
