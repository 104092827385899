import * as Sentry from "@sentry/react";
import { applyMiddleware, compose, createStore } from "redux";
import { persistStore } from "redux-persist";
import { thunk } from "redux-thunk";

import { verifyAuth } from "./actions/auth";
import rootReducer from "./reducers";

export const configureStore = (initialState) => {
  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options listed below
  });

  const middlewares = [];

  const composeEnhancers =
    (process.env.NODE_ENV === "development"
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            trace: true,
            traceLimit: 25,
          })
        : null
      : null) || compose;

  middlewares.push(applyMiddleware(thunk), sentryReduxEnhancer);
  const store = createStore(rootReducer, initialState, composeEnhancers(...middlewares));
  store.dispatch(verifyAuth());
  const persistor = persistStore(store);

  // persistor.purge();
  // persistor.flush();

  return { store, persistor };
};
