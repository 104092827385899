import CardStats from "components/Cards/CardStats";
import React, { useEffect, useState, lazy, Suspense, useCallback, memo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { fetchCommercialProperties, fetchResidentialProperties } from "state/actions/users";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toastr } from "react-redux-toastr";
import * as yup from "yup";
import classNames from "classnames";
import Field from "components/Field";
import { calculateGroups, createArray, fileTypes, getMonths, readFile } from "utils";
import { collection, doc, getDoc, setDoc, Timestamp } from "firebase/firestore";
import { newAuth, newFirestore, newStorage } from "utils/firebase";
import { chunk, find, findIndex, get, merge, omit, pick, reduce, snakeCase, uniq } from "lodash";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { endOfYear } from "date-fns";
import residentialProps from "pages/Residential/residentialProps";

import "./Tabs.css";
import { MultiSelect } from "react-multi-select-component";

const AnimatedTabs = ({ tabs, tabValue, setTabValue }) => {
  const handleChange = (index) => {
    setTabValue(index);
  };

  return (
    <div className="tabs-container">
      <div className="tabs">
        {tabs?.map((tab, index) => {
          return (
            <button key={index} className={`tab ${tabValue === index ? "active" : ""}`} onClick={() => handleChange(index)}>
              {tab}
            </button>
          );
        })}
      </div>
    </div>
  );
};

const Modal = lazy(() => import("components/SendNotification/NotificationModal"));
const ListATable = lazy(() => import("components/ListATable"));

const UploadInvoice = ({ changedProps, setChangedProps }) => {
  const rentData = changedProps.rentData;

  const schema = yup.object().shape({
    paymentPeriod: yup.string().required(),
    invoice: yup.mixed().test("fileSize", "The file is too large", (value) => {
      if (!value.length) return false;
      return value[0].size <= 2000000;
    }),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    return () => {
      reset({
        paymentPeriod: "",
        invoice: [],
      });
    };
  }, []);

  const [tabValue, setTabValue] = useState(0);
  const [processing, setProcessing] = useState(false);

  const tabs = ["CAM", "Rent"];

  const paymentGroups =
    rentData?.leaseStartDate && rentData?.leaseEndDate && rentData?.paymentTerms
      ? calculateGroups(rentData?.leaseStartDate?.toDate(), rentData?.leaseEndDate?.toDate(), rentData?.paymentTerms)
      : 0;

  const periods = getMonths(rentData?.leaseStartDate?.toDate(), rentData?.leaseEndDate?.toDate());
  const chunked = chunk(periods, paymentGroups.chunk);

  const onSubmit = async (values) => {
    try {
      setProcessing(true);
      toastr.info("Adding invoice", "Please wait...");

      const range = JSON.parse(values.paymentPeriod);
      const first = range?.at(0);
      const last = range?.at(-1);
      const paymentPeriod = first === last ? first : `${first} to ${last}`;
      let docID = snakeCase(paymentPeriod);

      let payload = {
        ...values,
        paymentType: tabs[tabValue],
      };

      const file = values?.invoice?.[0];

      if (file) {
        const storageRef = ref(
          newStorage,
          `${payload.paymentType}Invoices/` + changedProps?.selectedEstateID + "/" + rentData?.id + "/" + paymentPeriod + `.${fileTypes[file.type] || "jpg"}`,
        );
        const imageDataUrl = await readFile(file);
        const snapshot = await uploadString(storageRef, imageDataUrl, "data_url");
        const downloadURL = await getDownloadURL(snapshot.ref);

        const docRef = doc(collection(newFirestore, `estates/${changedProps?.selectedEstateID}/units/${rentData?.id}/${payload?.paymentType}Invoices`), docID);
        payload = {
          invoice: downloadURL,
          periodRange: paymentPeriod,
          period: range,
          type: payload?.paymentType,
          dateAdded: Timestamp.now(),
          dateUpdated: Timestamp.now(),
          id: docID,
          tenants: uniq(rentData?.tenants?.map((tenant) => get(tenant, "uid"))),
        };

        await setDoc(docRef, payload, { merge: true });
      }

      toastr.success("Invoice added");
      reset({
        paymentPeriod: "",
        invoice: [],
      });
      setChangedProps((data) => ({ ...data, reRun: new Date(), selectedEstateID: changedProps?.selectedEstateID }));
    } catch (error) {
      console.log(error);
      toastr.error(error?.message);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <Modal
        isActive={changedProps?.uploadInvoice}
        isLoading={false}
        confirmButtonMessage={`Send`}
        title={`Upload Invoice for ${tabs[tabValue]} (${rentData?.name})`}
        cancelButtonMessage={`Cancel`}
        onConfirmation={() => setChangedProps((data) => ({ ...data, uploadInvoice: false }))}
        onCancel={() => setChangedProps((data) => ({ ...data, uploadInvoice: false }))}
        showButtons={false}
        // cardClasses="!w-[70vw]"
      >
        <AnimatedTabs {...{ tabs, tabValue, setTabValue }} />
        <div className="flex flex-col items-center">
          <div className="w-full bg-white p-3">
            <div className="flex w-full justify-center">
              <div className="w-full">
                <form onSubmit={handleSubmit(onSubmit)} className="flex w-full flex-col gap-2">
                  <Field
                    {...{
                      register,
                      errors,
                      label: "Payment For",
                      field: `paymentPeriod`,
                      Input: (
                        <select
                          disabled={processing}
                          {...register(`paymentPeriod`)}
                          className={classNames(`input`, {
                            "is-danger": errors?.[`paymentPeriod`]?.message,
                          })}
                        >
                          <option value="">Select Option</option>
                          {createArray(paymentGroups.total).map((_, index) => {
                            const range = chunked[index];
                            const first = range?.at(0);
                            const last = range?.at(-1);
                            const paymentPeriodText = first === last ? first : `${first} to ${last}`;
                            return (
                              <option key={index} value={JSON.stringify(range)}>
                                {paymentPeriodText}
                              </option>
                            );
                          })}
                        </select>
                      ),
                    }}
                  />

                  <Field
                    {...{
                      register,
                      errors,
                      label: "Invoice",
                      field: `invoice`,
                      type: "file",
                      accept: "image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      disabled: processing,
                      multiple: false,
                    }}
                  />

                  <div className="flex justify-center">
                    <button disabled={processing} className="button is-primary" onClick={handleSubmit(onSubmit)}>
                      Upload Invoice
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const InvoicesOrReceipts = ({ changedProps, setChangedProps }) => {
  const rentData = changedProps.rentData;

  const [tabValue, setTabValue] = useState(0);
  const [typesValue, setTypesValue] = useState(0);

  const tabs = ["CAM", "Rent"];
  const types = ["Invoices", "Receipts"];

  return (
    <>
      <Modal
        isActive={changedProps?.showInvoicesOrReceipts}
        isLoading={false}
        confirmButtonMessage={`Send`}
        title={`View ${tabs[tabValue]} for ${rentData?.name}`}
        cancelButtonMessage={`Cancel`}
        onConfirmation={() => setChangedProps((data) => ({ ...data, showInvoicesOrReceipts: false }))}
        onCancel={() => setChangedProps((data) => ({ ...data, showInvoicesOrReceipts: false }))}
        showButtons={false}
        cardClasses="!w-[70vw]"
      >
        <AnimatedTabs {...{ tabs, tabValue, setTabValue }} />
        <AnimatedTabs {...{ tabs: types, tabValue: typesValue, setTabValue: setTypesValue }} />
        <ListATable
          key={tabValue + typesValue}
          columns={(props) =>
            residentialProps.viewInvoicesOrReceipts({ ...props, invoicesOrReceiptsTabValue: tabs[tabValue], invoicesOrReceiptsTypesValue: types[typesValue] })
          }
          actionButtons={residentialProps.actionButtons}
          data={() =>
            residentialProps.getInvoicesAndReceipts({
              ...rentData,
              documentType: `${tabs[tabValue]}${types[typesValue]}`,
              paymentType: `${tabs[tabValue]}`,
              docType: `${types[typesValue]}`,
              ...pick(changedProps, ["selectedEstateID"]),
            })
          }
        />
      </Modal>
    </>
  );
};

const ViewPayments = ({ changedProps, setChangedProps }) => {
  const rentData = changedProps.rentData;

  const [tabValue, setTabValue] = useState(0);

  const tabs = ["CAM", "Rent"];

  return (
    <>
      <Modal
        isActive={changedProps?.showPaymentsModal}
        isLoading={false}
        confirmButtonMessage={`Send`}
        title={`View Payments for ${rentData?.name}`}
        cancelButtonMessage={`Cancel`}
        onConfirmation={() => setChangedProps((data) => ({ ...data, showPaymentsModal: false }))}
        onCancel={() => setChangedProps((data) => ({ ...data, showPaymentsModal: false }))}
        showButtons={false}
        cardClasses="!w-[70vw]"
      >
        <AnimatedTabs {...{ tabs, tabValue, setTabValue }} />
        <ListATable
          key={tabValue}
          columns={residentialProps.viewPaymentsColumns}
          actionButtons={residentialProps.actionButtons}
          data={() =>
            residentialProps.getAllPayments({
              ...rentData,
              documentType: `${tabs[tabValue]}Payments`,
              ...pick(changedProps, ["selectedEstateID"]),
            })
          }
        />
      </Modal>
    </>
  );
};

const AddPayment = ({ changedProps, setChangedProps, groupIdentifier }) => {
  const rentData = changedProps.rentData;

  const schema = yup.object().shape({
    paymentType: yup.string().required(),
    paymentMethod: yup.string().required(),
    invoiceNumber: yup.string().required(),
    paymentAmount: yup.string().required(),
    paymentDate: yup.string().required(),
    paymentPeriod: yup.array().min(1).required(),
    balanceAmount: yup.string(),
    receipt: yup.mixed().test("fileSize", "The file is too large", (value) => {
      if (!value.length) return false;
      return value[0].size <= 2000000;
    }),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { preferences, userData } = useSelector((state) => ({
    loading: state.users.loading,
    userData: state.auth.userData,
    estateData: state.auth.estateData,
    preferences: state.preferences,
    ...state.users,
  }));

  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    return () => {
      reset({
        paymentType: "",
        paymentMethod: "",
        invoiceNumber: "",
        paymentAmount: "",
        paymentDate: "",
        paymentPeriod: "",
        balanceAmount: "",
        receipt: [],
      });
    };
  }, []);

  const paymentGroups =
    rentData?.leaseStartDate && rentData?.leaseEndDate && rentData?.paymentTerms
      ? calculateGroups(rentData?.leaseStartDate?.toDate(), rentData?.leaseEndDate?.toDate(), rentData?.paymentTerms)
      : 0;

  const periods = getMonths(rentData?.leaseStartDate?.toDate(), rentData?.leaseEndDate?.toDate());
  const chunked = chunk(periods, paymentGroups.chunk);

  const watchPaymentPeriod = watch("paymentPeriod", []);

  const onSubmit = async (values) => {
    try {
      setProcessing(true);
      toastr.info("Adding payment", "Please wait...");
      const { uid } = newAuth.currentUser;

      const run = values?.paymentPeriod.map(async (period, index) => {
        const range = JSON.parse(period.value);

        const first = range?.at(0);
        const last = range?.at(-1);
        const paymentPeriod = first === last ? first : `${first} to ${last}`;
        let docID = snakeCase(paymentPeriod);

        let payload = {
          ...omit(values, ["balanceAmount"]),
          ...(values.paymentAmount ? { paymentAmount: parseFloat(values.paymentAmount / values?.paymentPeriod?.length) } : {}),
          ...(values.balanceAmount && index === values?.paymentPeriod?.length - 1 ? { balanceAmount: parseFloat(values.balanceAmount) } : {}),
          ...pick(rentData, ["name", "currency"]),
          paymentDate: Timestamp.fromDate(new Date(values.paymentDate)),
          dateAdded: Timestamp.now(),
          dateUpdated: Timestamp.now(),
          addedBy: uid,
          updatedBy: uid,
          groupIdentifier,
          estateID: changedProps?.selectedEstateID,
          paymentStatus: "paid",
          paymentPeriod: range,
          paymentPeriodRange: paymentPeriod,
          id: docID,
          unitID: rentData?.id,
          unitName: rentData?.name,
          tenants: uniq(rentData?.tenants?.map((tenant) => get(tenant, "uid"))),
        };

        const file = payload?.receipt?.[0];

        if (file) {
          const storageRef = ref(
            newStorage,
            `${payload.paymentType}Receipts/` + changedProps?.selectedEstateID + "/" + rentData?.id + "/" + paymentPeriod + `.${fileTypes[file.type] || "jpg"}`,
          );
          const imageDataUrl = await readFile(file);
          const snapshot = await uploadString(storageRef, imageDataUrl, "data_url");
          const downloadURL = await getDownloadURL(snapshot.ref);
          payload["receipt"] = downloadURL;

          const docRef = doc(
            collection(newFirestore, `estates/${changedProps?.selectedEstateID}/units/${rentData?.id}/${payload?.paymentType}Receipts`),
            docID,
          );
          await setDoc(
            docRef,
            {
              receipt: downloadURL,
              periodRange: paymentPeriod,
              period: range,
              type: payload?.paymentType,
              dateAdded: Timestamp.now(),
              dateUpdated: Timestamp.now(),
              id: docID,
              tenants: uniq(rentData?.tenants?.map((tenant) => get(tenant, "uid"))),
            },
            { merge: true },
          );
        }

        delete payload["receipt"];
        // console.log(payload);
        // return;

        const docRef = doc(collection(newFirestore, `estates/${changedProps?.selectedEstateID}/units/${rentData?.id}/${payload?.paymentType}Payments`), docID);
        await setDoc(docRef, { ...payload }, { merge: true });
      });

      await Promise.all(run);
      toastr.success("Payment added");
      reset({
        paymentType: "",
        paymentMethod: "",
        invoiceNumber: "",
        paymentAmount: "",
        paymentDate: "",
        paymentPeriod: "",
        balanceAmount: "",
        receipt: [],
      });
      setChangedProps((data) => ({ ...data, reRun: new Date(), selectedEstateID: changedProps?.selectedEstateID }));
    } catch (error) {
      console.log(error);
      toastr.error(error?.message);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <Modal
        isActive={changedProps?.showAddPaymentModal}
        isLoading={processing}
        confirmButtonMessage={`Send`}
        title={`Add Payment ${changedProps?.rentData?.name}`}
        cancelButtonMessage={`Cancel`}
        onConfirmation={() => setChangedProps((data) => ({ ...data, showAddPaymentModal: false }))}
        onCancel={() => setChangedProps((data) => ({ ...data, showAddPaymentModal: false }))}
        showButtons={false}
      >
        <div className="flex flex-col items-center">
          <div className="w-full bg-white p-3">
            <div className="flex w-full justify-center">
              <div className="w-full">
                <form onSubmit={handleSubmit(onSubmit)} className="flex w-full flex-col gap-2">
                  <Field
                    {...{
                      register,
                      errors,
                      label: "Payment Type",
                      field: `paymentType`,
                      Input: (
                        <select
                          disabled={processing}
                          {...register(`paymentType`)}
                          className={classNames(`input`, {
                            "is-danger": errors?.[`paymentType`]?.message,
                          })}
                        >
                          <option value="">Select Option</option>
                          <option>CAM</option>
                          <option>Rent</option>
                        </select>
                      ),
                    }}
                  />

                  <Field
                    {...{
                      register,
                      errors,
                      label: "Payment Method",
                      field: `paymentMethod`,
                      Input: (
                        <select
                          disabled={processing}
                          {...register(`paymentMethod`)}
                          className={classNames(`input`, {
                            "is-danger": errors?.[`paymentMethod`]?.message,
                          })}
                        >
                          <option value="">Select Option</option>
                          <option>Bank Transfer</option>
                          <option>Credit/Debit Card</option>
                          <option>Digital Wallet</option>
                          <option>MOMO Payment</option>
                          <option>Direct Debit</option>
                          <option>Cheque</option>
                          <option>Cash</option>
                        </select>
                      ),
                    }}
                  />

                  <Field
                    {...{
                      register,
                      errors,
                      label: "Invoice Number",
                      field: `invoiceNumber`,
                      type: "text",
                      disabled: processing,
                    }}
                  />

                  <Field
                    {...{
                      register,
                      errors,
                      label: "Payment Amount",
                      field: `paymentAmount`,
                      type: "number",
                      disabled: true,
                    }}
                  />

                  <Field
                    {...{
                      register,
                      errors,
                      label: "Payment Date",
                      field: `paymentDate`,
                      type: "date",
                      className: `!block`,
                      disabled: processing,
                    }}
                  />

                  <Field
                    {...{
                      register,
                      errors,
                      label: "Payment For",
                      field: `paymentPeriod`,
                      Input: (
                        <MultiSelect
                          labelledBy={"Payment For"}
                          hasSelectAll={true}
                          value={watchPaymentPeriod}
                          options={createArray(paymentGroups.total).map((_, index) => {
                            const range = chunked[index];
                            const first = range?.at(0);
                            const last = range?.at(-1);
                            const paymentPeriodText = first === last ? first : `${first} to ${last}`;
                            const v = JSON.stringify(range);
                            return {
                              label: paymentPeriodText,
                              value: v,
                            };
                          })}
                          onChange={(value) => {
                            let amount = 0;
                            setValue("paymentPeriod", value);
                            const values = value.map((v) => JSON.parse(v.value));

                            const rentRate = rentData?.rentRate;
                            const CAMRatePerMonth = rentData?.CAMRatePerMonth;

                            values.forEach((range) => {
                              const lengthOfPeriod = range?.length;
                              amount += getValues("paymentType") === "Rent" ? rentRate : CAMRatePerMonth * lengthOfPeriod;
                            });

                            if (amount) {
                              setValue("paymentAmount", amount);
                            }
                          }}
                        />

                        // <select
                        //   disabled={processing}
                        //   {...register(`paymentPeriod`, {
                        //     onChange: (e) => {
                        //       const rentRate = rentData?.rentRate;
                        //       const CAMRatePerMonth = rentData?.CAMRatePerMonth;
                        //       const parsed = JSON.parse(e.target.value || "[]");
                        //       const lengthOfPeriod = parsed?.length;
                        //       const amount = getValues("paymentType") === "Rent" ? rentRate : CAMRatePerMonth;
                        //       if (amount) {
                        //         setValue("paymentAmount", amount * lengthOfPeriod);
                        //       }
                        //     },
                        //   })}
                        //   className={classNames(`input`, {
                        //     "is-danger": errors?.[`paymentPeriod`]?.message,
                        //   })}
                        // >
                        //   <option value="">Select Option</option>
                        //   {createArray(paymentGroups.total).map((_, index) => {
                        //     const range = chunked[index];
                        //     const first = range?.at(0);
                        //     const last = range?.at(-1);
                        //     const paymentPeriodText = first === last ? first : `${first} to ${last}`;
                        //     return (
                        //       <option key={index} value={JSON.stringify(range)}>
                        //         {paymentPeriodText}
                        //       </option>
                        //     );
                        //   })}
                        // </select>
                      ),
                    }}
                  />

                  <Field
                    {...{
                      register,
                      errors,
                      label: "Receipt",
                      field: `receipt`,
                      type: "file",
                      accept: "image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      disabled: processing,
                      multiple: false,
                    }}
                  />

                  <Field
                    {...{
                      register,
                      errors,
                      label: "Balance",
                      field: `balanceAmount`,
                      type: "number",
                      disabled: processing,
                    }}
                  />

                  <div className="flex justify-center">
                    <button disabled={processing} className="button is-primary" onClick={handleSubmit(onSubmit)}>
                      Add Payment
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const RentHeader = ({ tableData, changedProps, setChangedProps }) => {
  const dispatch = useDispatch();
  const { addToast, updateToast } = useToasts();

  const { preferences, allResidentialProperties, allCommercialProperties, userData } = useSelector(
    (state) => ({
      preferences: state.preferences,
      allResidentialProperties: state.users.allResidentialProperties,
      allCommercialProperties: state.users.allCommercialProperties,
      userData: state.auth.userData,
    }),
    shallowEqual,
  );

  const { propertyType, userClaims } = preferences;

  const { claims = {} } = userData;
  const { groupIdentifier = claims?.groupIdentifier } = userClaims;

  const allEstates = propertyType === "residential" ? allResidentialProperties : allCommercialProperties;

  useEffect(() => {
    if (propertyType === "commercial") {
      dispatch(
        fetchCommercialProperties({
          commercialPropertyIDs: userClaims?.commercialPropertyIDs,
          showNotif: false,
        }),
      );
    }

    if (propertyType === "residential") {
      dispatch(
        fetchResidentialProperties({
          estateIDs: userClaims?.estateID,
          showNotif: false,
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (allEstates?.length) {
      setChangedProps((data) => ({ ...data, selectedEstateID: allEstates?.[0]?.estateID }));
    }
  }, [allEstates]);

  const [rentInfo, setRentInfo] = useState({
    RentExpected: 0,
    CAMExpected: 0,
    RentCollected: 0,
    CAMCollected: 0,
  });

  useEffect(() => {
    if (tableData?.length) {
      const run = async () => {
        const res = tableData?.map(async (unit) => {
          const calculateTotalExpected = async (unit, type) => {
            const paymentGroupsTillEndOfYear = calculateGroups(unit?.leaseStartDate?.toDate(), endOfYear(new Date()), unit?.paymentTerms);

            const data = {
              [`${type}Expected`]: (type === "Rent" ? unit?.rentRate : unit?.CAMRatePerMonth || 0) * paymentGroupsTillEndOfYear?.totalMonths || 0,
              [`${type}Collected`]: 0,
            };

            const periods = getMonths(unit?.leaseStartDate?.toDate(), endOfYear(new Date()));
            const chunked = chunk(periods, paymentGroupsTillEndOfYear.chunk);

            const payments = createArray(paymentGroupsTillEndOfYear.total).map(async (_, index) => {
              const range = chunked[index];
              const rangeLength = range?.length;

              const first = range?.at(0);
              const last = range?.at(-1);
              const paymentPeriodText = first === last ? first : `${first} to ${last}`;
              const slug = snakeCase(paymentPeriodText);

              const docRef = doc(collection(newFirestore, `estates/${changedProps?.selectedEstateID}/units/${unit?.id}/${type}Payments`), slug);
              const exists = (await getDoc(docRef)).exists();

              // console.log(exists, slug, type);

              if (exists) {
                data[`${type}Collected`] += type === "Rent" ? unit?.rentRate : unit?.CAMRatePerMonth * rangeLength;
              }
            });

            await Promise.all(payments);

            return data;
          };
          const d1 = await calculateTotalExpected(unit, "Rent");
          const d2 = await calculateTotalExpected(unit, "CAM");

          return merge(d1, d2);
        });

        const calculated = await Promise.all(res);
        // console.log(calculated);
        const calculatedMerged = calculated.reduce(
          (acc, curr) => {
            acc.RentExpected += curr.RentExpected;
            acc.RentCollected += curr.RentCollected;
            acc.CAMExpected += curr.CAMExpected;
            acc.CAMCollected += curr.CAMCollected;
            return acc;
          },
          { RentExpected: 0, RentCollected: 0, CAMExpected: 0, CAMCollected: 0 },
        );
        // console.log(calculatedMerged);
        setRentInfo(calculatedMerged);
      };
      run();
    }
  }, [tableData]);

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        {changedProps?.showAddPaymentModal && <AddPayment {...{ changedProps, setChangedProps, groupIdentifier }} />}
        {changedProps?.showPaymentsModal && <ViewPayments {...{ changedProps, setChangedProps, groupIdentifier }} />}
        {changedProps?.uploadInvoice && <UploadInvoice {...{ changedProps, setChangedProps, groupIdentifier }} />}
        {changedProps?.showInvoicesOrReceipts && <InvoicesOrReceipts {...{ changedProps, setChangedProps, groupIdentifier }} />}
      </Suspense>

      <div className="mb-4">
        <div className="mb-4 w-full lg:w-5/12 xl:w-3/12">
          <label className="font-medium text-gray-700">Facility: {allEstates?.find((e) => e?.estateID === changedProps?.selectedEstateID)?.name}</label>
          {allEstates?.length > 1 && (
            <select
              onChange={(e) => setChangedProps((data) => ({ ...data, reRun: new Date(), selectedEstateID: e.target.value }))}
              defaultValue={allEstates?.[0]?.estateID}
              className="w-full rounded border border-[#dbdbdb]"
            >
              {allEstates.map((estate, index) => {
                return (
                  <option key={index} value={estate?.estateID}>
                    {estate?.name}
                  </option>
                );
              })}
            </select>
          )}
        </div>

        <div className="flex flex-wrap">
          <div className="mb-4 w-full cursor-pointer lg:w-6/12 xl:w-3/12">
            <CardStats
              statSubtitle="RENT EXPECTED"
              statTitle={rentInfo.RentExpected}
              statArrow="up"
              statPercent="3.48"
              statPercentColor="text-green-600"
              statDescripiron="Since last month"
              statIconName="fas fa-money-bill"
              statIconColor="bg-orange-400"
            />
          </div>
          <div className="mb-4 w-full cursor-pointer px-4 lg:w-6/12 xl:w-3/12">
            <CardStats
              statSubtitle="RENT COLLECTED"
              statTitle={rentInfo.RentCollected}
              statArrow="up"
              statPercent="3.48"
              statPercentColor="text-green-600"
              statDescripiron="Since last month"
              statIconName="fas fa-money-bill"
              statIconColor="bg-green-400"
            />
          </div>
          <div className="mb-4 w-full cursor-pointer px-4 lg:w-6/12 xl:w-3/12">
            <CardStats
              statSubtitle="CAM EXPECTED"
              statTitle={rentInfo.CAMExpected}
              statArrow="up"
              statPercent="3.48"
              statPercentColor="text-green-600"
              statDescripiron="Since last month"
              statIconName="fas fa-money-bill"
              statIconColor="bg-orange-500"
            />
          </div>
          <div className="mb-4 w-full cursor-pointer px-4 lg:w-6/12 xl:w-3/12">
            <CardStats
              statSubtitle="CAM COLLECTED"
              statTitle={rentInfo.CAMCollected}
              statArrow="up"
              statPercent="3.48"
              statPercentColor="text-green-600"
              statDescripiron="Since last month"
              statIconName="fas fa-money-bill"
              statIconColor="bg-green-500"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(RentHeader);
