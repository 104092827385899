import { createReducer } from "redux-act";

import { GET_ALL_USER_ESTATES, USERS_CLEAN_UP, USERS_CLEAR_DATA_LOGOUT, USERS_FAIL, USERS_FETCH_DATA_INIT, USERS_SUCCESS } from "state/actions/users";

const initialState = {
  loading: false,
  error: null,
  success: false,
  deleted: false,
  allUserEstates: [],
  allCommercialProperties: [],
  allResidentialProperties: [],
};

export const usersReducer = createReducer(
  {
    [USERS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [USERS_SUCCESS]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
    }),
    [USERS_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [USERS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [USERS_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
    [GET_ALL_USER_ESTATES]: (state, payload) => ({
      ...state,
      ...payload,
    }),
  },
  initialState,
);
