import CardStats from "components/Cards/CardStats";
import React, { useEffect, useState, lazy, Suspense, useCallback, memo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { fetchCommercialProperties, fetchResidentialProperties } from "state/actions/users";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toastr } from "react-redux-toastr";
import * as yup from "yup";
import classNames from "classnames";
import Field from "components/Field";
import { calculateGroups, createArray, fileTypes, getMonths, readFile } from "utils";
import { collection, doc, getDoc, setDoc, Timestamp } from "firebase/firestore";
import { newAuth, newFirestore, newStorage } from "utils/firebase";
import { chunk, find, findIndex, get, merge, omit, pick, reduce, snakeCase, uniq } from "lodash";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { endOfMonth, endOfYear, isWithinInterval, startOfMonth } from "date-fns";

const Modal = lazy(() => import("components/SendNotification/NotificationModal"));

const AddAsset = ({ changedProps, setChangedProps }) => {
  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    assetID: yup.string().required("Asset ID is required"),
    manufacturerModel: yup.string().required("Manufacturer and Model is required"),
    purchaseDate: yup.string().required("Purchase Date is required"),
    generalLocation: yup.string().required("General Location is required"),
    specificLocation: yup.string().required("Specific Location is required"),
    endOfLifeDate: yup.string().required("End of Life Date is required"),
    warrantyExpiryDate: yup.string().required("Warranty Expiry Date is required"),
    supplierVendor: yup.string().required("Supplier/Vendor is required"),
    category: yup.string().required("Category is required"),
    maintenanceScheduleDate: yup.string().required("Maintenance Schedule Date is required"),
    maintenanceSchedulePeriod: yup.string().required("Maintenance Schedule Period is required"),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { preferences, userData } = useSelector((state) => ({
    userData: state.auth.userData,
    preferences: state.preferences,
  }));

  const { userClaims } = preferences;

  const { claims = {} } = userData;
  const { groupIdentifier = claims?.groupIdentifier } = userClaims;

  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    return () => {
      reset({
        name: "",
        assetID: "",
        manufacturerModel: "",
        purchaseDate: "",
        generalLocation: "",
        specificLocation: "",
        endOfLifeDate: "",
        warrantyExpiryDate: "",
        supplierVendor: "",
        category: "",
        maintenanceScheduleDate: "",
        maintenanceSchedulePeriod: "",
      });
    };
  }, []);

  const onAddAsset = async (values) => {
    try {
      setProcessing(true);

      toastr.info("Adding asset", "Please wait...");
      const { uid } = newAuth.currentUser;
      const docRef = doc(collection(newFirestore, `estates/${changedProps?.selectedEstateID}/assets`));

      let payload = {
        ...values,
        dateAdded: Timestamp.now(),
        dateUpdated: Timestamp.now(),
        addedBy: uid,
        updatedBy: uid,
        id: docRef.id,
        purchaseDate: Timestamp.fromDate(new Date(values?.purchaseDate)),
        endOfLifeDate: Timestamp.fromDate(new Date(values?.endOfLifeDate)),
        warrantyExpiryDate: Timestamp.fromDate(new Date(values?.warrantyExpiryDate)),
        maintenanceScheduleDate: Timestamp.fromDate(new Date(values?.maintenanceScheduleDate)),
        estateID: changedProps?.selectedEstateID,
        groupIdentifier,
      };

      // console.log(payload);
      // return;

      await setDoc(docRef, { ...payload }, { merge: true });

      toastr.success("Asset added");
      reset({
        name: "",
        assetID: "",
        manufacturerModel: "",
        purchaseDate: "",
        generalLocation: "",
        specificLocation: "",
        endOfLifeDate: "",
        warrantyExpiryDate: "",
        supplierVendor: "",
        category: "",
        maintenanceScheduleDate: "",
        maintenanceSchedulePeriod: "",
      });
      setChangedProps((data) => ({ ...data, reRun: new Date(), addNewAsset: false, selectedEstateID: changedProps?.selectedEstateID }));
    } catch (error) {
      console.log(error);
      toastr.error(error?.message);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <Modal
        isActive={changedProps?.addNewAsset}
        isLoading={processing}
        confirmButtonMessage={`Send`}
        title={`Add Asset`}
        cancelButtonMessage={`Cancel`}
        onConfirmation={() => setChangedProps((data) => ({ ...data, addNewAsset: false }))}
        onCancel={() => setChangedProps((data) => ({ ...data, addNewAsset: false }))}
        showButtons={false}
      >
        <div className="flex flex-col items-center">
          <div className="w-full bg-white p-3">
            <div className="flex w-full justify-center">
              <div className="w-full">
                <form onSubmit={handleSubmit(onAddAsset)} className="flex w-full flex-col gap-2">
                  <Field
                    {...{
                      register,
                      errors,
                      label: "Name",
                      field: `name`,
                      type: "text",
                      disabled: processing,
                    }}
                  />
                  <Field
                    {...{
                      register,
                      errors,
                      label: "Unique Asset ID",
                      field: `assetID`,
                      type: "text",
                      disabled: processing,
                    }}
                  />
                  <Field
                    {...{
                      register,
                      errors,
                      label: "Manufacturer and Model",
                      field: `manufacturerModel`,
                      type: "text",
                      disabled: processing,
                    }}
                  />
                  <Field
                    {...{
                      register,
                      errors,
                      label: "Purchase Date",
                      field: `purchaseDate`,
                      type: "date",
                      className: `!block`,
                      disabled: processing,
                    }}
                  />
                  <Field
                    {...{
                      register,
                      errors,
                      label: "General Location",
                      field: `generalLocation`,
                      type: "text",
                      disabled: processing,
                    }}
                  />
                  <Field
                    {...{
                      register,
                      errors,
                      label: "Specific Location",
                      field: `specificLocation`,
                      type: "text",
                      disabled: processing,
                    }}
                  />
                  <Field
                    {...{
                      register,
                      errors,
                      label: "End of Life Date",
                      field: `endOfLifeDate`,
                      type: "date",
                      className: `!block`,
                      disabled: processing,
                    }}
                  />
                  <Field
                    {...{
                      register,
                      errors,
                      label: "Warranty Expiry Date",
                      field: `warrantyExpiryDate`,
                      type: "date",
                      className: `!block`,
                      disabled: processing,
                    }}
                  />
                  <Field
                    {...{
                      register,
                      errors,
                      label: "Supplier/Vendor",
                      field: `supplierVendor`,
                      type: "text",
                      disabled: processing,
                    }}
                  />
                  <Field
                    {...{
                      register,
                      errors,
                      label: "Category",
                      field: `category`,
                      Input: (
                        <select
                          disabled={processing}
                          {...register(`category`)}
                          className={classNames(`input`, {
                            "is-danger": errors?.[`category`]?.message,
                          })}
                        >
                          <option value="">Select Option</option>
                          <option>Building Structure</option>
                          <option>Electrical Systems</option>
                          <option>Plumbing and Water Systems</option>
                          <option>HVAC Systems</option>
                          <option>Furniture and Fixtures</option>
                          <option>IT and Communication Equipment</option>
                          <option>Security Systems</option>
                          <option>Appliances</option>
                          <option>Vehicles and Transportation</option>
                          <option>Landscaping and Outdoor</option>
                          <option>Signage and Decor</option>
                        </select>
                      ),
                    }}
                  />
                  <Field
                    {...{
                      register,
                      errors,
                      label: "Maintenance Schedule",
                      field: `maintenanceSchedule`,
                      Input: (
                        <div className="flex gap-2">
                          <input
                            type="date"
                            {...register(`maintenanceScheduleDate`)}
                            className={classNames(`input`, {
                              "is-danger": errors?.[`maintenanceScheduleDate`]?.message,
                            })}
                            disabled={processing}
                          />
                          <select
                            {...register(`maintenanceSchedulePeriod`)}
                            className={classNames(`input`, {
                              "is-danger": errors?.[`maintenanceSchedulePeriod`]?.message,
                            })}
                            disabled={processing}
                          >
                            <option value="">Select Period</option>
                            <option value="weekly">Weekly</option>
                            <option value="monthly">Monthly</option>
                            <option value="yearly">Yearly</option>
                          </select>
                        </div>
                      ),
                    }}
                  />
                  <div className="flex justify-center">
                    <button disabled={processing} className="button is-primary" onClick={handleSubmit(onAddAsset)}>
                      Add Asset
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const AssetsHeader = ({ tableData, changedProps, setChangedProps, tableProcessing }) => {
  const dispatch = useDispatch();
  const { addToast, updateToast } = useToasts();

  const { preferences, allResidentialProperties, allCommercialProperties } = useSelector(
    (state) => ({
      preferences: state.preferences,
      allResidentialProperties: state.users.allResidentialProperties,
      allCommercialProperties: state.users.allCommercialProperties,
    }),
    shallowEqual,
  );

  const { propertyType, userClaims } = preferences;

  const allEstates = propertyType === "residential" ? allResidentialProperties : allCommercialProperties;

  useEffect(() => {
    if (propertyType === "commercial") {
      dispatch(
        fetchCommercialProperties({
          commercialPropertyIDs: userClaims?.commercialPropertyIDs,
          showNotif: false,
        }),
      );
    }

    if (propertyType === "residential") {
      dispatch(
        fetchResidentialProperties({
          estateIDs: userClaims?.estateID,
          showNotif: false,
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (allEstates?.length) {
      setChangedProps((data) => ({ ...data, selectedEstateID: allEstates?.[0]?.estateID }));
    }
  }, [allEstates]);

  const info = {
    total: tableData?.length || 0,
    recent:
      tableData.filter((item) => isWithinInterval(item?.dateAdded?.toDate(), { start: startOfMonth(new Date()), end: endOfMonth(new Date()) }))?.length || 0,
    endOfLife: tableData.filter((item) => isWithinInterval(item?.endOfLifeDate?.toDate(), { start: new Date(), end: endOfYear(new Date()) }))?.length || 0,
  };

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>{changedProps?.addNewAsset && <AddAsset {...{ changedProps, setChangedProps }} />}</Suspense>

      <div className="mb-4">
        <div className="mb-4 w-full lg:w-5/12 xl:w-3/12">
          <label className="font-medium text-gray-700">Facility: {allEstates?.find((e) => e?.estateID === changedProps?.selectedEstateID)?.name}</label>
          {allEstates?.length > 1 && (
            <select
              onChange={(e) => setChangedProps((data) => ({ ...data, reRun: new Date(), selectedEstateID: e.target.value }))}
              defaultValue={allEstates?.[0]?.estateID}
              className="w-full rounded border border-[#dbdbdb]"
            >
              {allEstates.map((estate, index) => {
                return (
                  <option key={index} value={estate?.estateID}>
                    {estate?.name}
                  </option>
                );
              })}
            </select>
          )}
        </div>

        <div className="flex flex-wrap">
          <div className="mb-4 w-full cursor-pointer lg:w-6/12 xl:w-3/12">
            <CardStats
              statSubtitle="TOTAL ASSETS"
              statTitle={info.total}
              statArrow="up"
              statPercent="3.48"
              statPercentColor="text-green-600"
              statDescripiron="Since last month"
              statIconName="fas fa-fan"
              statIconColor="bg-orange-400"
            />
          </div>
          <div className="mb-4 w-full cursor-pointer px-4 lg:w-6/12 xl:w-3/12">
            <CardStats
              statSubtitle="ADDED THIS MONTH"
              statTitle={info.recent}
              statArrow="up"
              statPercent="3.48"
              statPercentColor="text-green-600"
              statDescripiron="Since last month"
              statIconName="fas fa-fan"
              statIconColor="bg-green-400"
            />
          </div>
          <div className="mb-4 w-full cursor-pointer px-4 lg:w-6/12 xl:w-3/12">
            <CardStats
              statSubtitle="END OF LIFE"
              statTitle={info.endOfLife}
              statArrow="up"
              statPercent="3.48"
              statPercentColor="text-green-600"
              statDescripiron="Since last month"
              statIconName="fas fa-fan"
              statIconColor="bg-red-500"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AssetsHeader;
