// Sentry initialization should be imported first!
import * as Sentry from "@sentry/react";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import { ToastProvider } from "react-toast-notifications";
import { PersistGate } from "redux-persist/integration/react";
import "./config/instrument";

import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import "./index.css";
import "react-phone-number-input/style.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/css/main.css";

import ErrorBoundary from "components/ErrorBoundary";
import LanguageWrapper from "components/LanguageWrapper";

import Router from "./pages/Router";
import { configureStore } from "./state/store";

const { store, persistor } = configureStore({});

const App = (
  <>
    <Sentry.ErrorBoundary fallback={ErrorBoundary} showDialog={false}>
      <ToastProvider placement="top-center">
        <Provider store={store}>
          <LanguageWrapper>
            <PersistGate persistor={persistor}>
              <ReduxToastr
                newestOnTop={false}
                preventDuplicates
                position="top-center"
                getState={(state) => state.toastr}
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar
                closeOnToastrClick
              />
              <Router />
              <Analytics debug={false} />
              <SpeedInsights />
            </PersistGate>
          </LanguageWrapper>
        </Provider>
      </ToastProvider>
    </Sentry.ErrorBoundary>
  </>
);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(App);
