import CardStats from "components/Cards/CardStats";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchCommercialProperties, fetchResidentialProperties } from "state/actions/users";

const LeaseHeader = ({ tableData, tableRef, changedProps, setChangedProps }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { preferences, loading, userData, estateData, allResidentialProperties, allCommercialProperties, userClaims } = useSelector((state) => ({
    loading: state.users.loading,
    userData: state.auth.userData,
    estateData: state.auth.estateData,
    preferences: state.preferences,
    userClaims: state.auth?.userData?.claims,
    ...state.users,
  }));

  const { propertyType } = preferences;
  const allEstates = propertyType === "residential" ? allResidentialProperties : allCommercialProperties;

  useEffect(() => {
    if (propertyType === "commercial") {
      dispatch(
        fetchCommercialProperties({
          commercialPropertyIDs: userClaims?.commercialPropertyIDs,
          showNotif: false,
        }),
      );
    }

    if (propertyType === "residential") {
      dispatch(
        fetchResidentialProperties({
          estateIDs: userClaims?.estateID,
          showNotif: false,
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (allEstates?.length) {
      setChangedProps((data) => ({ ...data, selectedEstateID: allEstates?.[0]?.estateID }));
    }
  }, [allEstates]);

  const [leaseInfo, setLeaseInfo] = useState({
    unitsActiveLease: 0,
    unitsExpiredLease: 0,
    unitsPendingLease: 0,
  });

  useEffect(() => {
    if (changedProps?.selectedEstateID) {
      const found = allEstates?.find((item) => item?.id === changedProps?.selectedEstateID);

      setLeaseInfo({
        unitsActiveLease: found?.unitsActiveLease || 0,
        unitsExpiredLease: found?.unitsExpiredLease || 0,
        unitsPendingLease: found?.unitsPendingLease || 0,
      });
    }
  }, [allEstates, changedProps?.selectedEstateID]);

  return (
    <div className="mb-4">
      {allEstates?.length > 1 && (
        <div className="mb-4 w-full lg:w-5/12 xl:w-3/12">
          <label className="font-medium text-gray-700">Facility: {allEstates?.find((e) => e?.estateID === changedProps?.selectedEstateID)?.name}</label>
          <select
            onChange={(e) => setChangedProps((data) => ({ ...data, reRun: new Date(), selectedEstateID: e.target.value }))}
            defaultValue={allEstates?.[0]?.estateID}
            className="w-full rounded border border-[#dbdbdb]"
          >
            {allEstates.map((estate, index) => {
              return (
                <option key={index} value={estate?.estateID}>
                  {estate?.name}
                </option>
              );
            })}
          </select>
        </div>
      )}

      <div className="flex flex-wrap">
        <div className="mb-4 w-full cursor-pointer px-4 lg:w-6/12 xl:w-3/12">
          <CardStats
            statSubtitle="ACTIVE LEASE"
            statTitle={leaseInfo?.unitsActiveLease || 0}
            statArrow="up"
            statPercent="3.48"
            statPercentColor="text-green-600"
            statDescripiron="Since last month"
            statIconName="fas fa-file-word"
            statIconColor="bg-green-500"
          />
        </div>
        <div className="mb-4 w-full cursor-pointer px-4 lg:w-6/12 xl:w-3/12">
          <CardStats
            statSubtitle="EXPIRED LEASE"
            statTitle={leaseInfo?.unitsExpiredLease || 0}
            statArrow="up"
            statPercent="3.48"
            statPercentColor="text-green-600"
            statDescripiron="Since last month"
            statIconName="fas fa-file-word"
            statIconColor="bg-red-500"
          />
        </div>
        <div className="mb-4 w-full cursor-pointer px-4 lg:w-6/12 xl:w-3/12">
          <CardStats
            statSubtitle="PENDING RENEWAL"
            statTitle={leaseInfo?.unitsPendingLease || 0}
            statArrow="up"
            statPercent="3.48"
            statPercentColor="text-green-600"
            statDescripiron="Since last month"
            statIconName="fas fa-file-word"
            statIconColor="bg-gray-500"
          />
        </div>
      </div>
    </div>
  );
};

export default LeaseHeader;
