import "./NotificationModal.scss";

const NotificationModal = ({
  isActive,
  isLoading,
  title,
  children,
  confirmButtonMessage,
  onConfirmation,
  cancelButtonMessage,
  onCancel,
  showButtons = false,
  cardClasses,
}) => {
  const modifiers = isActive && "is-active";
  const loadingModifier = isLoading && "is-loading";
  cardClasses = `modal-card rounded-b-8 overflow-hidden rounded ${cardClasses}`;

  return (
    <div className={`modal ${modifiers}`}>
      <div className="modal-background" onClick={!isLoading ? onCancel : undefined} />
      <div className={cardClasses}>
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
        </header>
        <section className="modal-card-body">{children}</section>

        {showButtons && (
          <footer className="modal-card-foot">
            <button type="button" className={`button is-success ${loadingModifier}`} onClick={onConfirmation}>
              {confirmButtonMessage}
            </button>
            <button type="button" className="button is-danger" onClick={onCancel} disabled={isLoading}>
              {cancelButtonMessage}
            </button>
          </footer>
        )}
      </div>
    </div>
  );
};

export default NotificationModal;
