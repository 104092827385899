import { endOfWeek, startOfWeek } from "date-fns";
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import React, { lazy, Suspense, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import commercialProps from "pages/Commercial/commercialProps";
import complaintsProps from "pages/Complaints/complaintsProps";
import residentialProps from "pages/Residential/residentialProps";

import paths from "../../config/paths";
import { newFirestore } from "../../utils/firebase";

const HomeFreemium = lazy(() => import("pages/Home/HomeFreemium"));
const HomePremium = lazy(() => import("pages/Home/HomePremium"));
const NotFound = lazy(() => import("pages/NotFound"));
const PrivateRoute = lazy(() => import("./PrivateRoute"));
const CommercialResidentalPrivateRoute = lazy(() => import("./CommercialResidentalPrivateRoute"));
const ArrivingOrders = lazy(() => import("components/Dashboard/ArrivingOrders"));
const ShopDashboard = lazy(() => import("pages/ShopDashboard"));
const Login = lazy(() => import("pages/Login"));
const Users = lazy(() => import("pages/Users"));
const Profile = lazy(() => import("pages/Profile"));
const ResetPassword = lazy(() => import("pages/ResetPassword"));
const User = lazy(() => import("pages/User"));
const EmergencyContact = lazy(() => import("pages/EmergencyContact"));
const EmergencyContacts = lazy(() => import("pages/EmergencyContacts"));
const AccessCodes = lazy(() => import("pages/AccessCodes"));
const Messages = lazy(() => import("pages/Messages"));
const ViewReports = lazy(() => import("pages/Reports/ViewReports"));
const Officers = lazy(() => import("pages/Officers"));
const AddAdmin = lazy(() => import("pages/AddAdmin"));
const Admins = lazy(() => import("pages/Admins"));
const Shop = lazy(() => import("pages/Shop"));
const EstateLevy = lazy(() => import("pages/Finance/EstateLevy"));
const Projects = lazy(() => import("pages/Finance/Projects"));
const Vehicles = lazy(() => import("pages/Finance/Vehicles"));
const AddNewLevyForm = lazy(() => import("pages/Finance/AddNewLevy"));
const AddNewProject = lazy(() => import("pages/Finance/AddNewProject"));
const Inventory = lazy(() => import("pages/Inventory"));
const Orders = lazy(() => import("pages/Orders"));
const Transactions = lazy(() => import("pages/Transactions"));
const ShopSetup = lazy(() => import("pages/ShopSetup"));
const Shops = lazy(() => import("pages/Shops"));
const Service = lazy(() => import("pages/Service"));
const Services = lazy(() => import("pages/Services"));
const Chats = lazy(() => import("pages/Chats"));
const Variances = lazy(() => import("pages/Variances"));
const Reports = lazy(() => import("pages/Reports"));
const HotelDashboard = lazy(() => import("pages/Hotel/Dashboard"));
const Section = lazy(() => import("pages/Hotel/NewSection"));
const Visitors = lazy(() => import("pages/Hotel/Visitors"));
const Security = lazy(() => import("pages/Hotel/Security"));
const Sections = lazy(() => import("pages/Hotel/Sections"));
const AddUser = lazy(() => import("pages/Hotel/AddUser"));

//COMMERCIAL/RESIDENTIAL
const Landing = lazy(() => import("pages/Landing"));
const ResidentialDashboard = lazy(() => import("pages/Residential/ResidentialDashboard"));
const ListATable = lazy(() => import("components/ListATable"));
const AddEditProperty = lazy(() => import("pages/Residential/AddEditProperty"));
const CommercialDashboard = lazy(() => import("pages/Commercial/CommercialDashboard"));
const AddEditCommercialProperty = lazy(() => import("pages/Commercial/AddEditCommercialProperty"));
const AddEditPropertyAdmin = lazy(() => import("pages/Residential/AddEditPropertyAdmin"));
const AddEditUnits = lazy(() => import("pages/Residential/AddEditUnits"));
const RaiseComplaint = lazy(() => import("pages/Residential/RaiseComplaint"));

const RouterComponent = () => {
  const {
    userType,
    shopId,
    allResidentialProperties,
    propertyType,
    allCommercialProperties,
    userData,
    estateData = {},
  } = useSelector(
    (state) => ({
      userType: state.auth?.userData?.type || state.auth?.userData?.userType,
      shopId: state.auth.userData.shopId,
      propertyType: state.preferences.propertyType,
      allResidentialProperties: state.users.allResidentialProperties,
      allCommercialProperties: state.users.allCommercialProperties,
      userData: state.auth.userData,
      estateData: state.auth.estateData,
    }),
    shallowEqual,
  );

  const { claims = {} } = userData;
  const { groupIdentifier } = claims;

  const [ordersList, setOrdersList] = React.useState([]);

  useEffect(() => {
    let unsubscribe;

    if (userType?.includes("shop_")) {
      unsubscribe = onSnapshot(
        query(
          collection(newFirestore, `shops/${shopId}/orders`),
          where("verified", "==", true),
          where("timestamp", ">=", startOfWeek(new Date())),
          where("timestamp", "<=", endOfWeek(new Date())),
          orderBy("timestamp", "desc"),
        ),

        (querySnapshot) => {
          var docs = [];
          querySnapshot.forEach((doc) => {
            const data = doc?.data();
            const timestamp = data?.timestamp?.toDate();
            docs.push({ ...data, timestamp });
          });

          setOrdersList(docs);
        },
      );
    }

    return unsubscribe;
  }, [userType]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <BrowserRouter>
        <Routes>
          <Route path={paths.LOGIN} element={<Login />} />
          <Route path={paths.RESET_PASSWORD} element={<ResetPassword />} />
          <Route
            path={paths.ADD_USER}
            element={
              <PrivateRoute>
                <User />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.MODIFY_USER}
            element={
              <PrivateRoute>
                <User />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.ADD_EMERGENCY_CONTACT}
            element={
              <PrivateRoute>
                <EmergencyContact />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.RESIDENTS}
            element={
              <PrivateRoute>
                <Users />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.OFFICERS}
            element={
              <PrivateRoute>
                <Officers />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.EMERGENCY_CONTACTS}
            element={
              <PrivateRoute>
                <EmergencyContacts />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.ADMINS}
            element={
              <PrivateRoute>
                <Admins />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.SHOPS}
            element={
              <PrivateRoute>
                <Shops />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.ACCESSCODES}
            element={
              <PrivateRoute>
                <AccessCodes />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.MESSAGES}
            element={
              <PrivateRoute>
                <Messages />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.CHATS}
            element={
              <PrivateRoute>
                <Chats />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.PROFILE}
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.VIEWREPORTS}
            element={
              <PrivateRoute>
                <ViewReports />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.ADDNEWLEVY}
            element={
              <PrivateRoute>
                <AddNewLevyForm />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.ADDNEWPROJECT}
            element={
              <PrivateRoute>
                <AddNewProject />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.PROJECTS}
            element={
              <PrivateRoute>
                <Projects />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.VEHICLES}
            element={
              <PrivateRoute>
                <Vehicles />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.ESTATELEVY}
            element={
              <PrivateRoute>
                <EstateLevy />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.REPORTS}
            element={
              <PrivateRoute>
                <Reports />
              </PrivateRoute>
            }
          />

          {/* service paths */}
          <Route
            path={paths.ADD_NEW_SERVICE_PERSON}
            element={
              <PrivateRoute>
                <Service />
              </PrivateRoute>
            }
          />

          <Route
            path={paths.FACILITY_INFORMATION}
            element={
              <PrivateRoute>
                <ListATable columns={residentialProps.propertyColumns} data={() => [estateData]} />
              </PrivateRoute>
            }
          />

          {/* shop paths */}
          <Route
            path={paths.ADDNEWSHOP}
            element={
              <PrivateRoute>
                <Shop />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.INVENTORY}
            element={
              <PrivateRoute>
                <Inventory />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.VARIANCES}
            element={
              <PrivateRoute>
                <Variances />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.ORDERS}
            element={
              <PrivateRoute>
                <Orders />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.TRANSACTIONS}
            element={
              <PrivateRoute>
                <Transactions />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.SHOP_SETUP}
            element={
              <PrivateRoute>
                <ShopSetup />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.ADD_ADMINS}
            element={
              <PrivateRoute>
                <AddAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.SERVICES}
            element={
              <PrivateRoute>
                <Services />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.COMPLAINTS}
            element={
              <PrivateRoute>
                <ListATable
                  columns={complaintsProps.columns}
                  data={() => complaintsProps.getAllComplaints({ estateData })}
                  TableTopComponent={complaintsProps.TableTopComponent}
                  actionButtons={complaintsProps.actionButtons}
                />
              </PrivateRoute>
            }
          />

          <Route
            path={paths.RAISE_FACILITY_COMPLAINT}
            element={
              <PrivateRoute>
                <RaiseComplaint />
              </PrivateRoute>
            }
          />

          {/* hotel paths */}
          <Route
            path={paths.ADDNEWSECTION}
            element={
              <PrivateRoute>
                <Section />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.VISITORS}
            element={
              <PrivateRoute>
                <Visitors />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.SECURITY}
            element={
              <PrivateRoute>
                <Security />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.SECTIONS}
            element={
              <PrivateRoute>
                <Sections />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.ADD_HOTEL_USER}
            element={
              <PrivateRoute>
                <AddUser />
              </PrivateRoute>
            }
          />

          {/* commercial/residential paths */}
          <Route path={paths.LANDING} element={<Landing />} />
          <Route
            path={paths.PROPERTIES}
            element={
              <CommercialResidentalPrivateRoute>
                <ListATable columns={residentialProps.propertyColumns} data={() => allResidentialProperties} />
              </CommercialResidentalPrivateRoute>
            }
          />
          <Route
            path={paths.ADD_PROPERTY}
            element={
              <CommercialResidentalPrivateRoute>
                <AddEditProperty />
              </CommercialResidentalPrivateRoute>
            }
          />
          <Route
            path={paths.COMMERCIAL_PROPERTIES}
            element={
              <CommercialResidentalPrivateRoute>
                <ListATable columns={commercialProps.propertyColumns} data={() => allCommercialProperties} />
              </CommercialResidentalPrivateRoute>
            }
          />
          <Route
            path={paths.ADD_COMMERCIAL_PROPERTY}
            element={
              <CommercialResidentalPrivateRoute>
                <AddEditCommercialProperty />
              </CommercialResidentalPrivateRoute>
            }
          />
          {/* <Route
            path={paths.ADD_COMMERCIAL_TENANT}
            element={
              <CommercialResidentalPrivateRoute>
                <AddEditCommercialTenant />
              </CommercialResidentalPrivateRoute>
            }
          /> */}
          <Route
            path={paths.ADD_NEW_ADMIN}
            element={
              <CommercialResidentalPrivateRoute>
                <AddEditPropertyAdmin />
              </CommercialResidentalPrivateRoute>
            }
          />
          <Route
            path={paths.ADD_EDIT_UNIT}
            element={
              <CommercialResidentalPrivateRoute>
                <AddEditUnits />
              </CommercialResidentalPrivateRoute>
            }
          />
          <Route
            path={paths.PROPMANAGERS}
            element={
              <CommercialResidentalPrivateRoute>
                <ListATable columns={residentialProps.adminsColumns} data={() => residentialProps.getAllPropertyManagers({ groupIdentifier })} />
              </CommercialResidentalPrivateRoute>
            }
          />

          <Route
            path={paths.PROP_COMPLAINTS}
            element={
              <CommercialResidentalPrivateRoute>
                <ListATable
                  columns={residentialProps.complaintsColumns}
                  data={() => residentialProps.getAllPropertyComplaints({ groupIdentifier })}
                  TableTopComponent={residentialProps.TableTopComponent}
                  actionButtons={residentialProps.actionButtons}
                />
              </CommercialResidentalPrivateRoute>
            }
          />

          <Route
            path={paths.RAISE_COMPLAINT}
            element={
              <CommercialResidentalPrivateRoute>
                <RaiseComplaint />
              </CommercialResidentalPrivateRoute>
            }
          />

          <Route
            path={paths.COMMERCIAL_COMPLAINTS}
            element={
              <CommercialResidentalPrivateRoute>
                <ListATable
                  columns={commercialProps.complaintsColumns}
                  data={() => commercialProps.getAllCommercialComplaints({ groupIdentifier })}
                  TableTopComponent={commercialProps.TableTopComponent}
                  actionButtons={commercialProps.actionButtons}
                />
              </CommercialResidentalPrivateRoute>
            }
          />
          <Route
            path={paths.LEASE_INFO}
            element={
              <CommercialResidentalPrivateRoute>
                <ListATable
                  columns={residentialProps.leaseColumns}
                  data={(props) => residentialProps.getAllActiveUnits({ ...props, groupIdentifier })}
                  TableTopComponent={residentialProps.LeaseHeader}
                  actionButtons={residentialProps.actionButtons}
                />
              </CommercialResidentalPrivateRoute>
            }
          />

          <Route
            path={paths.CAM_RENT}
            element={
              <CommercialResidentalPrivateRoute>
                <ListATable
                  columns={residentialProps.rentColumns}
                  data={(props) => residentialProps.getAllActiveUnits({ ...props, groupIdentifier })}
                  TableTopComponent={residentialProps.RentHeader}
                  actionButtons={residentialProps.actionButtons}
                />
              </CommercialResidentalPrivateRoute>
            }
          />
          <Route
            path={paths.ASSETS}
            element={
              <CommercialResidentalPrivateRoute>
                <ListATable
                  columns={residentialProps.assetsColumns}
                  data={(props) => residentialProps.getAssets({ ...props, groupIdentifier })}
                  TableTopComponent={residentialProps.AssetsHeader}
                  actionButtons={(props) => residentialProps.actionButtons({ ...props, ExtraComponents: residentialProps.AssetActionButtons })}
                />
              </CommercialResidentalPrivateRoute>
            }
          />

          {/* root */}
          <Route
            path={paths.ROOT}
            element={
              userType?.includes("shop_") ? (
                <PrivateRoute>
                  <ShopDashboard {...{ ordersList }} />
                </PrivateRoute>
              ) : userType?.includes("hotel_") ? (
                <PrivateRoute>
                  <HotelDashboard />
                </PrivateRoute>
              ) : ["residential"].includes(propertyType) ? (
                <CommercialResidentalPrivateRoute>
                  <ResidentialDashboard />
                </CommercialResidentalPrivateRoute>
              ) : ["commercial"].includes(propertyType) ? (
                <CommercialResidentalPrivateRoute>
                  <CommercialDashboard />
                </CommercialResidentalPrivateRoute>
              ) : (
                <PrivateRoute>
                  <HomePremium />
                </PrivateRoute>
              )
            }
          />
          <Route element={<NotFound />} />
        </Routes>
        {userType?.includes("shop_") && <ArrivingOrders {...{ ordersList }} />}
      </BrowserRouter>
    </Suspense>
  );
};

export default RouterComponent;
