import { format } from "date-fns";
import { collection, doc, getDoc, getDocs, orderBy, query, Timestamp, updateDoc, where } from "firebase/firestore";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { debounce, pick, uniq } from "lodash";
import { toastr } from "react-redux-toastr";

import { readFile, replaceElementById, tableActionButtons } from "utils";
import { newFirestore, newStorage } from "utils/firebase";
import paths from "config/paths";
import { callSingleEstateData } from "state/actions/auth";

import AssetsHeader from "components/Misc/AssetsHeader";
import Escalate from "components/Misc/Escalate";
import LeaseHeader from "components/Misc/LeaseHeader";
import RentHeader from "components/Misc/RentHeader";
import TableTopComponent from "components/Misc/TableTopComponent";
import { SelectColumnFilter } from "components/Table";

export const propertyColumns = ({ userClaims, dispatch, navigate }) => [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: `Type`,
    accessor: "type",
  },
  {
    Header: `Location`,
    accessor: "location",
  },
  {
    Header: `Residents`,
    accessor: "residentsAdded",
  },
  {
    Header: `Residents Onboarded`,
    accessor: "residentsOnboarded",
  },
  {
    Header: `Officers`,
    accessor: "officersAdded",
  },
  {
    Header: `Officers Onboarded`,
    accessor: "officersOnboarded",
  },
  {
    Header: `Access Created`,
    accessor: "accessCodesCreated",
  },

  {
    Header: `Access Granted`,
    accessor: "accessCodesGranted",
  },
  {
    Header: `Access Denied`,
    accessor: "accessCodesDenied",
  },

  {
    Header: `Emergency Contacts`,
    accessor: "emergencyContactsAdded",
  },
  {
    Header: `Logo`,
    accessor: "appConfigurations",
    Cell: ({ value }) => {
      return <img className="h-16 w-16" src={value?.logo} />;
    },
  },
  {
    Header: `ID`,
    accessor: "uid",
  },
  {
    Header: `Added By`,
    accessor: "addedBy",
  },
  {
    Header: "Actions",
    id: "actions",
    accessor: "actions",
    Cell: ({ row }) => {
      return (
        <>
          <div className="buttons is-right">
            <button
              className="button is-small is-primary"
              onClick={() => {
                dispatch(
                  callSingleEstateData({
                    claims: {
                      estateID: row.original.uid,
                    },
                    userClaims: pick(userClaims, ["estateID"]),
                  }),
                );
                navigate(`/`);
              }}
            >
              <span className="icon is-small">
                <i className="mdi mdi-eye" />
              </span>
            </button>
            <button
              className="button is-small is-danger"
              onClick={() => navigate(paths.ADD_PROPERTY, { state: { isEditing: true, facilityData: row?.original } })}
            >
              <span className="icon is-small">
                <i className="mdi mdi-pencil" />
              </span>
            </button>
          </div>
        </>
      );
    },
    disableSortBy: true,
  },
];

export const adminsColumns = ({}) => [
  {
    Header: "Name",
    accessor: "name",
  },
];

export const getAllPropertyManagers = async ({ groupIdentifier }) => {
  const docs = [];
  const querySnapshot = await getDocs(
    query(
      collection(newFirestore, "userClaims"),
      where("groupIdentifier", "==", groupIdentifier),
      // orderBy("checkIn", "desc"),
    ),
  );

  querySnapshot.forEach((doc) => {
    const data = doc?.data();
    docs.push(data);
  });

  return docs;
};

export const getAllPropertyComplaints = async ({ groupIdentifier }) => {
  const docs = [];
  const querySnapshot = await getDocs(
    query(
      collection(newFirestore, "issues"),
      where("topic", "==", "residentialIssues"),
      where("groupIdentifier", "==", groupIdentifier),
      orderBy("dateUpdated", "desc"),
    ),
  );

  querySnapshot.forEach((doc) => {
    const data = doc?.data();
    docs.push(data);
  });

  return docs;
};

export const complaintsColumns = ({ changedProps, setChangedProps }) => {
  return [
    {
      Header: "Actions",
      id: "actions",
      accessor: "actions",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <>
            <Escalate {...{ changedProps, setChangedProps, row }} />
            <div className="buttons is-left">
              <button
                className="bg-red-500 px-2 py-1 text-white"
                onClick={() => {
                  setChangedProps((data) => ({ ...data, escalateIssueID: row.original.issueID }));
                }}
              >
                Escalate
              </button>
            </div>
          </>
        );
      },
    },
    {
      Header: "Reference",
      accessor: "referenceCode",
      Filter: SelectColumnFilter,
    },
    {
      Header: "Category",
      accessor: "category",
      Filter: SelectColumnFilter,
    },
    {
      Header: "Sub Category",
      accessor: "subCategory",
      Filter: SelectColumnFilter,
    },
    {
      Header: `Complaint`,
      accessor: "complaint",
      Cell: ({ value }) => {
        return <p className="">{value}</p>;
      },
      width: 250,
    },
    {
      Header: `Asset`,
      accessor: "image",
      Cell: ({ row, value }) => {
        return (
          <>
            {value ? (
              <a href={value} target="_blank">
                <img
                  id={row.original.issueID}
                  src={value}
                  className="hover:opacity-50"
                  onError={() => {
                    const video = document.createElement("video");
                    video.src = value;
                    video.autoplay = false;
                    video.setAttribute("controls", "controls");
                    replaceElementById(row.original.issueID, video);
                  }}
                />
              </a>
            ) : (
              <p>N/A</p>
            )}
          </>
        );
      },
    },
    {
      Header: `Notes`,
      accessor: "message",
    },
    {
      Header: `Raised For`,
      accessor: "uid",
      // Cell: ({ row }) => {
      //   return <p>{(row.original.name || "N/A") + "|" + (row.original.phoneNumber || "N/A")}</p>;
      // },
      Cell: ({ value }) => {
        return <p>{value || "N/A"}</p>;
      },
      // width: 250,
    },
    {
      Header: `Unit`,
      accessor: "houseNumber",
      Cell: ({ value }) => {
        return <p>{value || "N/A"}</p>;
      },
    },
    {
      Header: `Facility`,
      accessor: "estateName",
      Filter: SelectColumnFilter,
    },
    {
      Header: `Status`,
      accessor: "status",
      Filter: SelectColumnFilter,
    },
    {
      Header: `Assigned To`,
      accessor: "assignedFO",
      Cell: ({ value }) => {
        return <p>{value || "N/A"}</p>;
      },
      Filter: SelectColumnFilter,
    },
    {
      Header: `Assigned To (Phone Number)`,
      accessor: "assignedFOPhoneNumber",
      Cell: ({ value }) => {
        return <p>{value || "N/A"}</p>;
      },
    },
    {
      Header: `Assigned To (Email)`,
      accessor: "assignedFOEmail",
      Cell: ({ value }) => {
        return <p>{value || "N/A"}</p>;
      },
    },
    {
      Header: `Escalated To`,
      accessor: "escalatedTo",
      Cell: ({ value }) => {
        return <p>{value || "N/A"}</p>;
      },
    },
    {
      Header: `Escalated To (Phone Number)`,
      accessor: "escalatedToPhoneNumber",
      Cell: ({ value }) => {
        return <p>{value || "N/A"}</p>;
      },
    },
    {
      Header: `Escalated To (Email)`,
      accessor: "escalatedToEmail",
      Cell: ({ value }) => {
        return <p>{value || "N/A"}</p>;
      },
    },
    {
      Header: `Date Sent`,
      accessor: "dateSent",
      Cell: ({ value }) => {
        return <p>{value?.toDate ? format(value?.toDate(), "PPPp") : "N/A"}</p>;
      },
      width: 250,
    },
    {
      Header: `Date Updated`,
      accessor: "dateUpdated",
      Cell: ({ value }) => {
        return <p>{value?.toDate ? format(value?.toDate(), "PPPp") : "N/A"}</p>;
      },
      width: 250,
    },
    {
      Header: `Raised By`,
      accessor: "raisedBy",
      Cell: ({ row }) => {
        return <p>{row?.original?.name ? `${row?.original?.name}|${row?.original?.phoneNumber}` : row?.original?.raisedBy || "N/A"}</p>;
      },
    },
    {
      Header: `Updated By`,
      accessor: "updatedBy",
      Cell: ({ value }) => {
        return <p>{value || "N/A"}</p>;
      },
    },
    {
      Header: `Complaint ID`,
      accessor: "issueID",
    },
  ];
};

export const leaseColumns = ({ changedProps: { selectedEstateID }, setChangedProps }) => {
  return [
    {
      Header: "Unit",
      accessor: "name",
      Filter: SelectColumnFilter,
    },
    {
      Header: "Name",
      accessor: "tenantName",
      Cell: ({ row }) => {
        return <p>{Array.isArray(row?.original?.tenants) ? uniq(row?.original?.tenants?.slice(0, 1).map((t) => t?.name)).join(", ") : "N/A"}</p>;
      },
      Filter: SelectColumnFilter,
      filter: "includes",
    },
    {
      Header: "Phone Number",
      accessor: "tenantPhoneNumber",
      Cell: ({ row }) => {
        return <p>{Array.isArray(row?.original?.tenants) ? uniq(row?.original?.tenants?.slice(0, 1).map((t) => t?.uid)).join(", ") : "N/A"}</p>;
      },
      Filter: SelectColumnFilter,
    },
    {
      Header: "Lease Start Date",
      accessor: "leaseStartDate",
      Cell: ({ value, row }) => {
        return (
          <input
            className="input"
            type="date"
            defaultValue={value ? format(value?.toDate(), "yyyy-MM-dd") : ""}
            value={value ? format(value?.toDate(), "yyyy-MM-dd") : ""}
            onChange={async (e) => {
              e.preventDefault();
              await updateDoc(doc(newFirestore, `estates/${selectedEstateID}/units/${row?.original?.id}`), {
                leaseStartDate: Timestamp.fromDate(new Date(e.target.value)),
              });
              setChangedProps((data) => ({ ...data, reRun: new Date() }));
            }}
          />
        );
      },
    },
    {
      Header: "Lease End Date",
      accessor: "leaseEndDate",
      Cell: ({ value, row }) => {
        return (
          <input
            className="input"
            type="date"
            defaultValue={value ? format(value?.toDate(), "yyyy-MM-dd") : ""}
            value={value ? format(value?.toDate(), "yyyy-MM-dd") : ""}
            onChange={async (e) => {
              e.preventDefault();
              await updateDoc(doc(newFirestore, `estates/${selectedEstateID}/units/${row?.original?.id}`), {
                leaseEndDate: Timestamp.fromDate(new Date(e.target.value)),
              });
              setChangedProps((data) => ({ ...data, reRun: new Date() }));
            }}
          />
        );
      },
    },
    {
      Header: "Rent Amount",
      accessor: "rentRate",
      Cell: ({ row }) => {
        return `${row?.original?.currency} ${row?.original.rentRate || "N/A"}`;
      },
      Filter: SelectColumnFilter,
    },
    {
      Header: "Security Deposit",
      accessor: "securityDeposit",
      Cell: ({ value, row }) => {
        return (
          <input
            className="input !h-auto"
            defaultValue={value}
            onChange={debounce(async (e) => {
              e.preventDefault();
              await updateDoc(doc(newFirestore, `estates/${selectedEstateID}/units/${row?.original?.id}`), {
                securityDeposit: e.target.value ? parseFloat(e.target.value) : "",
              });
            }, 2000)}
          />
        );
      },
      width: 250,
      Filter: SelectColumnFilter,
    },
    {
      Header: "Payment Terms",
      accessor: "paymentTerms",
      Filter: SelectColumnFilter,
    },
    {
      Header: "Renewal Status",
      accessor: "leaseRenewalStatus",
      Filter: SelectColumnFilter,
    },
    {
      Header: "Contract",
      accessor: "contract",
      Cell: ({ value, row }) => {
        return (
          <>
            {value && (
              <>
                <a className="mb-2" href={value} target="_blank" rel="noopener noreferrer">
                  View Contract
                </a>
                <p className="my-2">Or upload a new one</p>
              </>
            )}

            <input
              type="file"
              accept="image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              onChange={async (e) => {
                const file = e.target.files[0];
                const types = {
                  "image/jpeg": "jpg",
                  "image/png": "png",
                  "application/pdf": "pdf",
                  "application/msword": "doc",
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
                };

                if (file) {
                  const storageRef = ref(newStorage, "leaseAgreements/" + selectedEstateID + "/" + row?.original?.id + `.${types[file.type] || "jpg"}`);
                  const imageDataUrl = await readFile(file);
                  const snapshot = await uploadString(storageRef, imageDataUrl, "data_url");
                  const downloadURL = await getDownloadURL(snapshot.ref);
                  await updateDoc(doc(newFirestore, `estates/${selectedEstateID}/units/${row?.original?.id}`), {
                    contract: downloadURL,
                  });
                  setChangedProps((data) => ({ ...data, reRun: new Date() }));
                }
              }}
            />
          </>
        );
      },
    },
    {
      Header: "Lease Type",
      accessor: "leaseType",
      Cell: ({ value, row }) => {
        return (
          <input
            className="input !h-auto"
            defaultValue={value}
            onChange={debounce(async (e) => {
              e.preventDefault();
              await updateDoc(doc(newFirestore, `estates/${selectedEstateID}/units/${row?.original?.id}`), {
                leaseType: e.target.value || "",
              });
            }, 2000)}
          />
        );
      },
      Filter: SelectColumnFilter,
      width: 250,
    },
    {
      Header: "Special Conditions",
      accessor: "specialConditions",
      Cell: ({ value, row }) => {
        return (
          <textarea
            className="input !h-auto"
            rows={5}
            defaultValue={value}
            onChange={debounce(async (e) => {
              e.preventDefault();
              await updateDoc(doc(newFirestore, `estates/${selectedEstateID}/units/${row?.original?.id}`), {
                specialConditions: e.target.value || "",
              });
            }, 2000)}
          />
        );
      },
      width: 250,
    },
  ];
};

export const getAllActiveUnits = async ({ groupIdentifier, ...props }) => {
  const { selectedEstateID } = props;
  const docs = [];
  const querySnapshot = await getDocs(
    query(
      collection(newFirestore, `estates/${selectedEstateID}/units`),
      where("status", "in", ["rented", "owner-occupied"]),
      // where("groupIdentifier", "==", groupIdentifier),
      orderBy("dateUpdated", "desc"),
    ),
  );

  querySnapshot.forEach((doc) => {
    const data = doc?.data();
    docs.push(data);
  });

  return docs;
};

export const rentColumns = ({ changedProps: { selectedEstateID }, setChangedProps }) => {
  return [
    {
      Header: "Unit",
      accessor: "name",
      Filter: SelectColumnFilter,
    },
    {
      Header: "Resident/Tenant Name",
      accessor: "tenantName",
      Cell: ({ row }) => {
        return <p>{Array.isArray(row?.original?.tenants) ? uniq(row?.original?.tenants?.slice(0, 1).map((t) => t?.name)).join(", ") : "N/A"}</p>;
      },
    },
    {
      Header: "CAM Amount",
      accessor: "CAMRatePerMonth",
      Cell: ({ row }) => {
        return `${row?.original?.currency} ${row?.original.CAMRatePerMonth || "N/A"}`;
      },
      Filter: SelectColumnFilter,
    },
    {
      Header: "Rent Amount",
      accessor: "rentRate",
      Cell: ({ row }) => {
        return `${row?.original?.currency} ${row?.original.rentRate || "N/A"}`;
      },
      Filter: SelectColumnFilter,
    },
    // {
    //   Header: "Due Date",
    //   accessor: "leaseEndDate",
    //   Cell: ({ value }) => {
    //     return <p>{value ? format(value?.toDate(), "PPPP") : "N/A"}</p>;
    //   },
    //   width: 250,
    //   Filter: SelectColumnFilter,
    // },
    // {
    //   Header: "Payment Status",
    //   accessor: "paymentStatus",
    //   Filter: SelectColumnFilter,
    // },
    {
      Header: "Payment Terms",
      accessor: "paymentTerms",
      Filter: SelectColumnFilter,
    },
    {
      Header: "Outstanding Balance",
      accessor: "outstandingBalance",
      Cell: ({ row }) => {
        return <p>{row?.original?.leaseStartDate && row?.original?.leaseEndDate ? "N/A" : "N/A"}</p>;
      },
    },
    {
      Header: "Upload CAM/Rent Invoice",
      accessor: "CAMInvoice",
      Cell: ({ value, row }) => {
        return (
          <button className="button is-small is-link" onClick={() => setChangedProps((data) => ({ ...data, uploadInvoice: true, rentData: row?.original }))}>
            Upload
          </button>
        );
      },
    },
    {
      Header: "Actions",
      id: "actions",
      accessor: "actions",
      Cell: ({ row }) => {
        const rentData = row?.original;
        return (
          <>
            <div className="flex items-center gap-x-2">
              <button
                className="button is-small is-link"
                onClick={() => setChangedProps((data) => ({ ...data, tabValue: 0, showInvoicesOrReceipts: true, rentData }))}
              >
                View Invoices/Receipts
              </button>
              <button
                className="button is-small is-success"
                onClick={() => setChangedProps((data) => ({ ...data, viewPaymentsTabValue: 0, showPaymentsModal: true, rentData }))}
              >
                View Payments
              </button>
              <button
                className="button is-small is-primary"
                onClick={() => {
                  if (!rentData?.leaseStartDate || !rentData?.leaseEndDate || !rentData?.tenants?.length) {
                    return toastr.error("Error", "Please lease start/end date and tenant details");
                  }
                  setChangedProps((data) => ({ ...data, showAddPaymentModal: true, rentData }));
                }}
              >
                Add Payment
              </button>
            </div>
          </>
        );
      },
      disableSortBy: true,
    },
  ];
};

export const getAllPayments = async ({ groupIdentifier, ...props }) => {
  let { selectedEstateID, id, documentType, tenants } = props;
  tenants = tenants?.map((r) => r?.uid);

  const docs = [];
  const querySnapshot = await getDocs(
    query(
      collection(newFirestore, `estates/${selectedEstateID}/units/${id}/${documentType}`),
      where("tenants", "array-contains-any", tenants),
      orderBy("dateAdded", "asc"),
    ),
  );

  querySnapshot.forEach((doc) => {
    const data = doc?.data();
    docs.push(data);
  });

  const getReceipts = docs.map(async (d) => {
    const collectionPath1 = `estates/${selectedEstateID}/units/${id}/${d?.paymentType}Receipts`;
    const collectionPath2 = `estates/${selectedEstateID}/units/${id}/${d?.paymentType}Invoices`;
    const receipt = (await getDoc(doc(collection(newFirestore, collectionPath1), d?.id))).data() || {};
    const invoice = (await getDoc(doc(collection(newFirestore, collectionPath2), d?.id))).data() || {};
    return { ...d, receipt, invoice };
  });

  return await Promise.all(getReceipts);
};

export const viewPaymentsColumns = ({ changedProps: { selectedEstateID }, setChangedProps }) => {
  return [
    {
      Header: "Unit",
      accessor: "unitName",
      Filter: SelectColumnFilter,
    },
    {
      Header: "Payment Type",
      accessor: "paymentType",
      Filter: SelectColumnFilter,
    },
    {
      Header: "Payment Amount",
      accessor: "paymentAmount",
      Cell: ({ row }) => {
        return `${row?.original?.currency} ${row?.original.paymentAmount || "N/A"}`;
      },
      Filter: SelectColumnFilter,
    },
    {
      Header: "Payment Date",
      accessor: "paymentDate",
      Cell: ({ value }) => {
        return <p>{value ? format(value?.toDate(), "PPPp") : "N/A"}</p>;
      },
      width: 250,
      // Filter: SelectColumnFilter,
    },
    {
      Header: "Payment Method",
      accessor: "paymentMethod",
      Filter: SelectColumnFilter,
    },
    {
      Header: "Payment Period",
      accessor: "paymentPeriodRange",
      Filter: SelectColumnFilter,
    },
    {
      Header: "Balance",
      accessor: "balanceAmount",
      Cell: ({ row }) => {
        return `${row?.original?.currency} ${row?.original.balanceAmount || "N/A"}`;
      },
      Filter: SelectColumnFilter,
    },
    {
      Header: `Invoice`,
      accessor: "invoice",
      Cell: ({ row }) => {
        return (
          <>
            {row?.original?.invoice?.invoice ? (
              <a href={row?.original?.invoice?.invoice} target="_blank" rel="noopener noreferrer">
                View Invoice
              </a>
            ) : (
              <p>N/A</p>
            )}
          </>
        );
      },
    },
    {
      Header: `Receipt`,
      accessor: "receipt",
      Cell: ({ row }) => {
        return (
          <>
            {row?.original?.receipt?.receipt ? (
              <a href={row?.original?.receipt?.receipt} target="_blank" rel="noopener noreferrer">
                View Receipt
              </a>
            ) : (
              <p>N/A</p>
            )}
          </>
        );
      },
    },
    {
      Header: "Payment Added",
      accessor: "dateAdded",
      Cell: ({ value }) => {
        return <p>{value ? format(value?.toDate(), "PPPp") : "N/A"}</p>;
      },
      width: 250,
    },
    {
      Header: "Payment Updated",
      accessor: "dateUpdated",
      Cell: ({ value }) => {
        return <p>{value ? format(value?.toDate(), "PPPp") : "N/A"}</p>;
      },
      width: 250,
    },
  ];
};

export const getInvoicesAndReceipts = async ({ groupIdentifier, ...props }) => {
  let { selectedEstateID, id, documentType, tenants, paymentType, docType } = props;
  tenants = tenants?.map((r) => r?.uid);

  const docs = [];
  const querySnapshot = await getDocs(
    query(
      collection(newFirestore, `estates/${selectedEstateID}/units/${id}/${documentType}`),
      where("tenants", "array-contains-any", tenants),
      orderBy("dateAdded", "asc"),
    ),
  );

  querySnapshot.forEach((doc) => {
    const data = doc?.data();
    docs.push(data);
  });

  return docs;
};
export const viewInvoicesOrReceipts = ({ changedProps: { selectedEstateID }, invoicesOrReceiptsTabValue, invoicesOrReceiptsTypesValue, ...props }) => {
  return [
    {
      Header: "Period",
      accessor: "periodRange",
      Filter: SelectColumnFilter,
    },
    {
      Header: "Type",
      accessor: "type",
    },
    {
      Header: invoicesOrReceiptsTypesValue.substring(0, invoicesOrReceiptsTypesValue.length - 1),
      accessor: "invoice",
      Cell: ({ row }) => {
        if (invoicesOrReceiptsTypesValue === "Invoices") {
          return (
            <>
              {row?.original?.invoice ? (
                <a href={row?.original?.invoice} target="_blank" rel="noopener noreferrer">
                  View Invoice
                </a>
              ) : (
                <p>N/A</p>
              )}
            </>
          );
        }

        if (invoicesOrReceiptsTypesValue === "Receipts") {
          return (
            <>
              {row?.original?.receipt ? (
                <a href={row?.original?.receipt} target="_blank" rel="noopener noreferrer">
                  View Receipt
                </a>
              ) : (
                <p>N/A</p>
              )}
            </>
          );
        }
      },
    },
    {
      Header: "Added",
      accessor: "dateAdded",
      Cell: ({ value }) => {
        return <p>{value ? format(value?.toDate(), "PPPp") : "N/A"}</p>;
      },
      width: 250,
    },
    {
      Header: "Updated",
      accessor: "dateUpdated",
      Cell: ({ value }) => {
        return <p>{value ? format(value?.toDate(), "PPPp") : "N/A"}</p>;
      },
      width: 250,
    },
  ];
};

export const getAssets = async ({ groupIdentifier, ...props }) => {
  const { selectedEstateID } = props;
  const docs = [];
  const querySnapshot = await getDocs(query(collection(newFirestore, `estates/${selectedEstateID}/assets`), orderBy("dateUpdated", "desc")));

  querySnapshot.forEach((doc) => {
    const data = doc?.data();
    docs.push(data);
  });

  return docs;
};

export const assetsColumns = ({ changedProps: { selectedEstateID }, setChangedProps }) => {
  return [
    {
      Header: "Name",
      accessor: "name",
      Filter: SelectColumnFilter,
    },
    {
      Header: "Unique Asset ID",
      accessor: "assetID",
      Filter: SelectColumnFilter,
    },
    {
      Header: "Manufacturer/Model",
      accessor: "manufacturerModel",
      Filter: SelectColumnFilter,
    },
    {
      Header: "Purchase Date",
      accessor: "purchaseDate",
      Cell: ({ value }) => {
        return <p>{value ? format(value?.toDate(), "PPPP") : "N/A"}</p>;
      },
      width: 250,
      // Filter: SelectColumnFilter,
    },
    {
      Header: "General Location",
      accessor: "generalLocation",
      Filter: SelectColumnFilter,
    },
    {
      Header: "Specific Location",
      accessor: "specificLocation",
      Filter: SelectColumnFilter,
    },
    {
      Header: `End of Life Date`,
      accessor: "endOfLifeDate",
      Cell: ({ value }) => {
        return <p>{value ? format(value?.toDate(), "PPPP") : "N/A"}</p>;
      },
      width: 250,
    },
    {
      Header: "Warranty Expiry Date",
      accessor: "warrantyExpiryDate",
      Cell: ({ value }) => {
        return <p>{value ? format(value?.toDate(), "PPPP") : "N/A"}</p>;
      },
      width: 250,
    },
    {
      Header: "Supplier/Vendor",
      accessor: "supplierVendor",
      Filter: SelectColumnFilter,
    },
    {
      Header: "Category",
      accessor: "category",
      Filter: SelectColumnFilter,
    },
    {
      Header: "Maintenance Schedule Date",
      accessor: "maintenanceScheduleDate",
      Cell: ({ value }) => {
        return <p>{value ? format(value?.toDate(), "PPPP") : "N/A"}</p>;
      },
      width: 250,
    },
    {
      Header: "Maintenance Schedule Period",
      accessor: "maintenanceSchedulePeriod",
      Filter: SelectColumnFilter,
    },
  ];
};

export const AssetActionButtons = ({ changedProps, setChangedProps }) => {
  const addAsset = () => {
    setChangedProps((data) => ({ ...data, addNewAsset: true }));
  };

  return (
    <div>
      <button className="rounded bg-green-500 px-2 py-1 text-white shadow" onClick={addAsset}>
        Add Asset
      </button>
    </div>
  );
};

const actionButtons = (props) => {
  return tableActionButtons({
    ...props,
    omittedColumns: ["Actions"],
    transformData: (item) => ({
      "Date Sent": typeof item["Date Sent"] === "string" ? item["Date Sent"] : item["Date Sent"]?.toDate()?.toUTCString(),
      "Date Updated": typeof item["Date Updated"] === "string" ? item["Date Updated"] : item["Date Updated"]?.toDate()?.toUTCString(),
    }),
  });
};

export default {
  propertyColumns,
  adminsColumns,
  getAllPropertyManagers,
  getAllPropertyComplaints,
  complaintsColumns,
  TableTopComponent,
  actionButtons,
  leaseColumns,
  LeaseHeader,
  getAllActiveUnits,
  RentHeader,
  rentColumns,
  viewPaymentsColumns,
  getAllPayments,
  assetsColumns,
  getAssets,
  AssetActionButtons,
  AssetsHeader,
  viewInvoicesOrReceipts,
  getInvoicesAndReceipts,
};
