import * as Sentry from "@sentry/react";
import {
  getMultiFactorResolver,
  onAuthStateChanged,
  PhoneAuthProvider,
  RecaptchaVerifier,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { omit, pick } from "lodash";
import { toastr } from "react-redux-toastr";
import { createAction } from "redux-act";

import { firebaseError, withOutId } from "utils";
import { COMMERCIAL, COMMERCIAL_PROPMANAGER, PROPMANAGER } from "utils/constants";

import { resetPreferences, setPropertyType } from "./preferences";
import { clearUsersDataLogout, fetchCommercialProperties, fetchResidentialProperties, fetchUserEstates } from "./users";
import { fetchDocument } from "../api";
import { newAuth, newFirestore } from "../../utils/firebase";

export const AUTH_SIGN_IN_INIT = createAction("AUTH_SIGN_IN_INIT");
export const AUTH_SIGN_IN_FAIL = createAction("AUTH_SIGN_IN_FAIL");

export const AUTH_RESTORE_SESSION_INIT = createAction("AUTH_RESTORE_SESSION_INIT");
export const AUTH_RESTORE_SESSION_SUCCESS = createAction("AUTH_RESTORE_SESSION_SUCCESS");
export const AUTH_RESTORE_SESSION_FAIL = createAction("AUTH_RESTORE_SESSION_FAIL");

export const AUTH_LOGOUT_INIT = createAction("AUTH_LOGOUT_INIT");
export const AUTH_LOGOUT_SUCCESS = createAction("AUTH_LOGOUT_SUCCESS");

export const AUTH_SET_PASSWORD_INIT = createAction("AUTH_SET_PASSWORD_INIT");
export const AUTH_SET_PASSWORD_SUCCESS = createAction("AUTH_SET_PASSWORD_SUCCESS");
export const AUTH_SET_PASSWORD_FAIL = createAction("AUTH_SET_PASSWORD_FAIL");

export const AUTH_RESET_PASSWORD_INIT = createAction("AUTH_RESET_PASSWORD_INIT");
export const AUTH_RESET_PASSWORD_SUCCESS = createAction("AUTH_RESET_PASSWORD_SUCCESS");
export const AUTH_RESET_PASSWORD_FAIL = createAction("AUTH_RESET_PASSWORD_FAIL");

export const AUTH_CLEAN_UP = createAction("AUTH_CLEAN_UP");

export const AUTH_FETCH_USER_DATA_INIT = createAction("AUTH_FETCH_USER_DATA_INIT");
export const AUTH_FETCH_USER_DATA_SUCCESS = createAction("AUTH_FETCH_USER_DATA_SUCCESS");
export const AUTH_FETCH_USER_DATA_FAIL = createAction("AUTH_FETCH_USER_DATA_FAIL");

export const AUTH_CHANGE_PASSWORD_INIT = createAction("AUTH_CHANGE_PASSWORD_INIT");
export const AUTH_CHANGE_PASSWORD_SUCCESS = createAction("AUTH_CHANGE_PASSWORD_SUCCESS");
export const AUTH_CHANGE_PASSWORD_FAIL = createAction("AUTH_CHANGE_PASSWORD_FAIL");

export const AUTH_UPDATE_USER_DATA = createAction("AUTH_UPDATE_USER_DATA");

export const AUTH_PROVIDER_INIT = createAction("AUTH_PROVIDER_INIT");

export const AUTH_PROVIDER_SUCCESS = createAction("AUTH_PROVIDER_SUCCESS");

export const AUTH_PROVIDER_FAIL = createAction("AUTH_PROVIDER_FAIL");
export const AUTH_GET_ESTATE_FAIL = createAction("AUTH_GET_ESTATE_FAIL");
export const AUTH_GET_ESTATE_SUCCESS = createAction("AUTH_GET_ESTATE_SUCCESS");
export const AUTH_GET_APP_CONFIG_SUCCESS = createAction("AUTH_GET_APP_CONFIG_SUCCESS");
export const VERIFY_USER = createAction("VERIFY_USER");

export const logout = () => {
  return async (dispatch) => {
    dispatch(AUTH_LOGOUT_INIT());
    dispatch(clearUsersDataLogout());
    dispatch(resetPreferences());
    await signOut(newAuth);
    dispatch(AUTH_LOGOUT_SUCCESS());
  };
};

export const verifyAuth = () => {
  return (dispatch) => {
    onAuthStateChanged(newAuth, (user) => {
      dispatch(AUTH_RESTORE_SESSION_INIT());

      if (user !== null) {
        return dispatch(AUTH_RESTORE_SESSION_SUCCESS());
      }

      dispatch(AUTH_RESTORE_SESSION_FAIL());
      return dispatch(logout());
    });
  };
};

export const fetchEstateData = (estateID) => {
  return async (dispatch) => {
    try {
      onSnapshot(doc(newFirestore, "estates", estateID), (doc) => dispatch(AUTH_GET_ESTATE_SUCCESS(doc.data())));
    } catch (error) {
      console.log(error);
      toastr.error("", error);
      return dispatch(AUTH_GET_ESTATE_FAIL({ error }));
    }
  };
};

export const fetchAppConfigurations = () => {
  return async (dispatch) => {
    try {
      onSnapshot(collection(newFirestore, "configuration"), (querySnapshot) => {
        const configs = {};
        querySnapshot.forEach((doc) => {
          configs[doc.id] = doc.data();
        });

        return dispatch(AUTH_GET_APP_CONFIG_SUCCESS(configs));
      });
    } catch (error) {
      console.log(error);
      toastr.error("", error);
      return dispatch(AUTH_GET_ESTATE_FAIL({ error }));
    }
  };
};

export const callSingleEstateData = ({ userClaims, claims }) => {
  return async (dispatch, getState) => {
    let user;
    try {
      const { uid } = newAuth.currentUser;
      user = await fetchDocument(`estates/${claims?.estateID}/admins`, uid);

      // fetchDocument always returns a document id, even if it doesn't exist
      if (withOutId(user)) {
        return dispatch(logout());
      }

      await dispatch(fetchEstateData(claims?.estateID));
      await dispatch(fetchAppConfigurations());
      const omitted = pick(user, ["email", "name", "estateID", "estateName"]);
      Sentry.setUser(omitted);
    } catch (error) {
      dispatch(logout());
      return dispatch(AUTH_FETCH_USER_DATA_FAIL({ error }));
    }

    // this is necessary to make switch property work
    const {
      userData: { typeSelected },
    } = getState().auth;
    const { propertyType } = getState().preferences;
    // claims not necessary here, check if it causes any issues in the future
    dispatch(
      AUTH_FETCH_USER_DATA_SUCCESS({
        ...user,
        typeSelected: typeSelected || propertyType,
        claims: userClaims,
        dateAdded: user?.dateAdded?.toDate().toUTCString(),
      }),
    );
    dispatch(setPropertyType({ propertyType: "" }));
  };
};

export const fetchUserData = ({ proceed = () => {}, proceedToMainPage = () => {} }) => {
  return async (dispatch) => {
    let userClaims;
    try {
      dispatch(AUTH_FETCH_USER_DATA_INIT());
      const { uid, displayName, email, phoneNumber } = newAuth.currentUser;
      const omitted = { uid, name: displayName, email, phoneNumber };
      Sentry.setUser(omitted);
      userClaims = await fetchDocument(`userClaims`, uid);
      const { userGroup } = userClaims;

      // set user properties here, check if setting user
      dispatch(setPropertyType({ userManagerType: userGroup, userClaims }));
      // claims is necessary here
      if ([COMMERCIAL_PROPMANAGER, PROPMANAGER, COMMERCIAL].includes(userGroup)) {
        dispatch(
          AUTH_FETCH_USER_DATA_SUCCESS({
            claims: userClaims,
          }),
        );

        if ([COMMERCIAL].includes(userGroup)) {
          // this allows for the user to be redirected to the main page if they have only one property
          // if (userClaims?.commercialPropertyIDs?.length === 1) {
          //   return dispatch(
          //     handleClickCommercial({
          //       commercialPropertyIDs: userClaims?.commercialPropertyIDs,
          //       proceed: () =>
          //         dispatch(
          //           callSingleEstateData({
          //             claims: {
          //               estateID: userClaims?.commercialPropertyIDs[0],
          //             },
          //             userClaims: { estateID: userClaims?.commercialPropertyIDs },
          //           })
          //         ),
          //     })
          //   );
          // }
          return dispatch(
            handleClickCommercial({
              commercialPropertyIDs: userClaims?.commercialPropertyIDs,
              proceed: proceedToMainPage,
            }),
          );
        }

        if ([PROPMANAGER].includes(userGroup)) {
          // this allows for the user to be redirected to the main page if they have only one property
          // if (userClaims?.estateID?.length === 1) {
          //   return dispatch(
          //     handleClickResidential({
          //       estateIDs: userClaims?.estateID,
          //       proceed: () =>
          //         dispatch(
          //           callSingleEstateData({
          //             claims: {
          //               estateID: userClaims?.estateID[0],
          //             },
          //             userClaims: { estateID: userClaims?.estateID },
          //           })
          //         ),
          //     })
          //   );
          // }
          dispatch(
            handleClickResidential({
              estateIDs: userClaims?.estateID,
            }),
          );

          // if user has only estate types of facilities, fetch the facility estates
          return dispatch(
            fetchUserEstates({
              estateIDs: userClaims?.estateID,
              proceed: proceedToMainPage,
            }),
          );
        }
        return proceed();
      }
    } catch (error) {
      console.log(error);
      dispatch(logout());
      return dispatch(AUTH_FETCH_USER_DATA_FAIL({ error }));
    }

    return dispatch(callSingleEstateData({ claims: userClaims, userClaims }));
  };
};

export const checkUserData = () => {
  return (dispatch, getState) => {
    const { id } = getState().auth.userData;

    if (!id) {
      dispatch(fetchUserData());
    }
  };
};

export const startMfaSignin = async (multiFactorHint, session) => {
  try {
    // const recaptchaVerifier = new RecaptchaVerifier("recaptcha", undefined, newAuth);
    const recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha",
      {
        size: "invisible",
      },
      newAuth,
    );

    const phoneInfoOptions = {
      multiFactorHint: multiFactorHint,
      session: session,
    };
    const phoneAuthProvider = new PhoneAuthProvider(newAuth);
    const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
    return verificationId;
  } catch (error) {
    console.log(error);
  }
};

export const auth = (email, password, proceed, proceedToMainPage) => {
  return async (dispatch, getState) => {
    try {
      dispatch(AUTH_SIGN_IN_INIT());
      dispatch(setPropertyType({ propertyType: "" }));
      try {
        await signInWithEmailAndPassword(newAuth, email, password);
      } catch (error) {
        console.log(error);

        if (error.code == "auth/multi-factor-auth-required") {
          const resolver = getMultiFactorResolver(newAuth, error);
          const { phoneNumber } = resolver.hints[0];
          const verificationId = await startMfaSignin(resolver.hints[0], resolver.session);
          dispatch(VERIFY_USER({ verify: true, verificationId, error }));
          return toastr.success("", `OTP sent to ${phoneNumber}`);
        }

        return dispatch(AUTH_SIGN_IN_FAIL({ error: error.message }));
      }

      return dispatch(fetchUserData({ proceed, proceedToMainPage }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const resetPassword = (email) => {
  return async (dispatch, getState) => {
    dispatch(AUTH_RESET_PASSWORD_INIT());
    const { locale } = getState().preferences;

    try {
      await sendPasswordResetEmail(newAuth, email);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      return dispatch(AUTH_RESET_PASSWORD_FAIL({ error: errorMessage }));
    }

    return dispatch(AUTH_RESET_PASSWORD_SUCCESS());
  };
};

export const authCleanUp = () => (dispatch) => dispatch(AUTH_CLEAN_UP());

export const handleClickCommercial = (props) => {
  return async (dispatch, getState) => {
    dispatch(setPropertyType({ propertyType: "commercial" }));
    dispatch(AUTH_UPDATE_USER_DATA({ typeSelected: "commercial" }));
    dispatch(
      fetchCommercialProperties({
        ...props,
      }),
    );
  };
};

export const handleClickResidential = (props) => {
  return async (dispatch, getState) => {
    dispatch(setPropertyType({ propertyType: "residential" }));
    dispatch(AUTH_UPDATE_USER_DATA({ typeSelected: "residential" }));
    dispatch(
      fetchResidentialProperties({
        ...props,
      }),
    );
    dispatch(
      fetchUserEstates({
        ...props,
      }),
    );
  };
};

export const handleBackToLanding = (props) => {
  return async (dispatch, getState) => {
    props.proceed();
  };
};

export const switchProperty = ({ proceed }) => {
  return async (dispatch, getState) => {
    try {
      // this is necessary to make switch property work
      const {
        userData: { typeSelected, claims },
      } = getState().auth;
      const { propertyType, userClaims } = getState().preferences;

      dispatch(
        AUTH_FETCH_USER_DATA_SUCCESS({
          // find a better way to do this
          claims: omit(userClaims, ["id"]),
        }),
      );

      dispatch(AUTH_GET_ESTATE_SUCCESS({}));
      dispatch(setPropertyType({ propertyType: typeSelected || propertyType }));
      proceed();
    } catch (error) {
      dispatch(logout());
      return dispatch(AUTH_FETCH_USER_DATA_FAIL({ error }));
    }
  };
};
