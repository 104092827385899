import { createAction } from "redux-act";

export const PREFERENCES_SET_LOCALE = createAction("PREFERENCES_SET_LOCALE");
export const SET_PROPERTY_TYPE = createAction("SET_PROPERTY_TYPE");
export const PREFERENCES_RESET = createAction("PREFERENCES_RESET");

export const setUserLocale = (locale) => (dispatch) => {
  return dispatch(PREFERENCES_SET_LOCALE({ locale }));
};

export const setPropertyType = (props) => (dispatch) => {
  return dispatch(SET_PROPERTY_TYPE(props));
};

export const resetPreferences = () => (dispatch) => {
  return dispatch(PREFERENCES_RESET());
};
