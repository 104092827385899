export default function CardStats({ statSubtitle, statTitle, statIconName, statIconColor, statDescription, estateData }) {
  return (
    <div className="relative mb-6 flex min-w-0 flex-col break-words rounded bg-blue-100 shadow-lg xl:mb-0">
      <div className="flex-auto p-4">
        <div className="flex flex-wrap">
          <div className="relative w-full max-w-full flex-1 flex-grow pr-4">
            <h5 className="text-blueGray-400 font-medium uppercase">{statSubtitle}</h5>
            <p className="whitespace-pre-line">{statTitle}</p>
            <p className="whitespace-pre-line">{statDescription}</p>
          </div>
          {statIconName && (
            <div className="relative w-auto flex-initial pl-4">
              <div className={"inline-flex h-7 w-7 items-center justify-center rounded-full p-3 text-center text-white shadow-lg " + statIconColor}>
                <i className={statIconName}></i>
              </div>
            </div>
          )}
          {!statIconName && (
            <div className="flex w-24 items-center justify-center">
              <img src={estateData?.appConfigurations?.logo} alt={estateData?.name} className="h-24 w-24" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

// {statIconName && (
//   <div className="flex w-24 items-center justify-center">
//     <div className={"inline-flex h-7 w-7 items-center justify-center rounded-full p-3 text-center text-white shadow-lg " + statIconColor}>
//       <i className={statIconName}></i>
//     </div>
//   </div>
// )}
// {!statIconName && (
//   <div className="flex w-24 items-center justify-center">
//     <img src={estateData?.appConfigurations?.logo} alt={estateData?.name} className="h-24 w-24" />
//   </div>
// )}
