import { createReducer } from "redux-act";

import { PREFERENCES_RESET, PREFERENCES_SET_LOCALE, SET_PROPERTY_TYPE } from "state/actions/preferences";

const initialState = {
  locale: null,
  propertyType: "",
};

export const preferencesReducer = createReducer(
  {
    [PREFERENCES_SET_LOCALE]: (state, payload) => ({
      ...state,
      locale: payload.locale,
    }),
    [SET_PROPERTY_TYPE]: (state, payload) => ({
      ...state,
      ...payload,
    }),
    [PREFERENCES_RESET]: () => ({
      ...initialState,
    }),
  },
  initialState,
);
