import classNames from "classnames";
import ErrorMessage from "components/ErrorMessage";
import React from "react";

const Field = ({ register, errors, label, field, Input, className = "", registerProps = {}, showLabel = true, disabled = false, ...props }) => {
  return (
    <>
      <div className="field is-horizontal">
        {showLabel && (
          <div className="field-label is-normal">
            <label className="label">{label}</label>
          </div>
        )}
        <div className="field-body">
          <div className="field">
            <div className="control">
              {Input || (
                <input
                  disabled={disabled}
                  {...register(field, registerProps)}
                  className={classNames(`input ${className}`, {
                    "is-danger": errors[field],
                  })}
                  {...props}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {errors[field] && (
        <div className="field is-horizontal !mb-0">
          <div className="field-label is-normal" />
          <div className="field-body">
            <ErrorMessage text={errors?.[field?.message]} />
          </div>
        </div>
      )}
    </>
  );
};

export default Field;
