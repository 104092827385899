import NotificationModal from "components/SendNotification/NotificationModal";
import { httpsCallable } from "firebase/functions";
import { pick } from "lodash";
import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";

import { complaintStatusColors } from "utils";
import { newFunction } from "utils/firebase";

export const TableTopComponent = ({
  tableRef,
  tableData,
  pickedData = ["name", "dateSent", "status", "complaint", "category", "subCategory", "estateName"],
  changedProps,
  setChangedProps,
}) => {
  const { addToast, updateToast } = useToasts();
  const [processing, setProcessing] = useState(false);
  const [analyzedData, setAnalyzedData] = useState("<p></p>");
  const [showModal, setShowModal] = useState(false);

  const anyalzeData = async () => {
    try {
      const selectedRows = tableRef.current.setSelectedRows();

      if (!selectedRows?.length) {
        return alert("Select rows to analyze");
      }

      setProcessing(true);
      addToast(`Analyzing data, please wait....`, {
        appearance: "info",
        id: "analyze-data",
      });

      const parsedData = tableData.slice(0, 5).map((complaint) => {
        return {
          ...pick(complaint, pickedData),
        };
      });

      const analyzeAdminData = httpsCallable(newFunction, "analyzeAdminData");
      const { data } = await analyzeAdminData({
        type: "complaints",
        adminData: parsedData,
      });

      updateToast(`analyze-data`, {
        appearance: data?.status ? "success" : "error",
        content: `Analyzed`,
        autoDismiss: true,
      });

      console.log(data.response);
      // const formattedData = JSON.parse(data.response);

      setAnalyzedData(data.response);
      setShowModal(true);
    } catch (error) {
      console.log(error);
      updateToast(`analyze-data`, {
        appearance: "error",
        content: error?.message,
        autoDismiss: true,
      });
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      {/* <NotificationModal
        isActive={showModal}
        isLoading={processing}
        confirmButtonMessage={`Send`}
        title={`Summarized Analyzed Data`}
        cancelButtonMessage={`Cancel`}
        onConfirmation={() => console.log("hello")}
        onCancel={() => setShowModal(false)}
        showButtons={false}
      >
        <div className="flex flex-col items-center" dangerouslySetInnerHTML={{ __html: analyzedData }}>
          <button>Send to</button>
        </div>
      </NotificationModal> */}
      <div className="flex flex-col justify-end lg:items-end">
        <p className="text-xs font-bold">Legend</p>
        <div className="flex flex-col items-end">
          {Object.entries(complaintStatusColors).map(([status, color]) => (
            <p className="text-xs" key={status}>
              <span className="font-bold" style={{ color }}>
                {status.replace(/_/g, " ")}:
              </span>{" "}
              <span>
                {status === "RECEIVED" && "The complaint has been received and is pending review."}
                {status === "IN_PROGRESS" && "The complaint has been reviewed, and action is currently being taken."}
                {status === "SCHEDULED" && "A service or inspection has been scheduled for a future date."}
                {status === "AWAITING_MATERIALS" && "The required materials or parts are being sourced before the issue can be resolved."}
                {status === "PENDING_APPROVAL" && "The complaint requires approval from management or another department before action can proceed."}
                {status === "UNDER_REVIEW" && "The issue is being assessed by the facility officer or a specialist."}
                {status === "ON_HOLD" && "The complaint is temporarily on hold due to external factors (e.g., waiting for third-party services)."}
                {status === "RESOLVED" && "The issue has been resolved, and no further action is required."}
                {status === "CLOSED" && "The complaint has been officially closed after resolution and any final follow-up."}
              </span>
            </p>
          ))}
          {/* <button disabled={processing} className={`${processing ? "bg-gray-300" : "bg-green-500"} my-2 rounded px-4 py-2 text-white`} onClick={anyalzeData}>
            Analyze with Our Gatekeeper AI
          </button> */}
        </div>
      </div>
    </>
  );
};

export default TableTopComponent;
