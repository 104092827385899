export const COMMERCIAL_PROPMANAGER = "commercial/propmanager";
export const PROPMANAGER = "propmanager";
export const COMMERCIAL = "commercial";

export const MANAGERS = {
  [COMMERCIAL_PROPMANAGER]: "Commercial/Residential PM",
  [PROPMANAGER]: "Residential PM",
  [COMMERCIAL]: "Commercial PM",
};

export default {
  COMMERCIAL_PROPMANAGER,
  PROPMANAGER,
  COMMERCIAL,
  MANAGERS,
};
