import { collection, getDocs, orderBy, query, where } from "firebase/firestore";

import { tableActionButtons } from "utils";
import { newFirestore } from "utils/firebase";

import TableTopComponent from "components/Misc/TableTopComponent";
import residentialProps from "pages/Residential/residentialProps";

export const getAllCommercialComplaints = async ({ groupIdentifier }) => {
  const docs = [];
  const querySnapshot = await getDocs(
    query(
      collection(newFirestore, "issues"),
      where("topic", "==", "commercialIssues"),
      where("groupIdentifier", "==", groupIdentifier),
      orderBy("dateUpdated", "desc"),
    ),
  );

  querySnapshot.forEach((doc) => {
    const data = doc?.data();
    docs.push(data);
  });

  return docs;
};

export const propertyColumns = residentialProps.propertyColumns;
export const complaintsColumns = residentialProps.complaintsColumns;

const actionButtons = (props) =>
  tableActionButtons({
    ...props,
    omittedColumns: ["Actions"],
    transformData: (item) => ({
      "Date Sent": typeof item["Date Sent"] === "string" ? item["Date Sent"] : item["Date Sent"]?.toDate()?.toUTCString(),
      "Date Updated": typeof item["Date Updated"] === "string" ? item["Date Updated"] : item["Date Updated"]?.toDate()?.toUTCString(),
    }),
  });

export default { propertyColumns, getAllCommercialComplaints, complaintsColumns, actionButtons, TableTopComponent };
